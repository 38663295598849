import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';
import { useHistory } from 'react-router';
import {
  Breadcrumb,
  TitleDescription,
  CardIncentiveCampaign,
  Select,
  LoadingIndicator
} from '@digi-tim-19/components';

import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';
import {
  SortFindManyIncentiveCampaignInput,
  EnumIncentiveCampaignStatus
} from '../../autogenerated/client/types';

import { FCampaignPagination as appendToFragment } from './fragments';
import { NotFound, CampaignSortSelect } from './styles';

const configPage = {
  pageName: 'Campanhas vigentes',
  icon: 'trophy',
  description: 'Campanhas de incentivo de resultados',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Para você', link: routes.home.mount() },
    { title: 'Campanhas', link: routes.campanhasIncentivoVigentes.mount() },
    { title: 'Vigentes', link: routes.campanhasIncentivoVigentes.mount() }
  ],
  pageSize: 20,
  empty: 'Não temos campanhas no momento'
};

export const CampanhaIncentivoVigentes = () => {
  const history = useHistory();
  const incentiveCampaignFind = useClient('IncentiveCampaignPagination');

  const [statusFilter, setStatusFilter] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [items, setItems] = useState<any>([]);
  const [statusINFilter, setStatusINFilter] = useState<any>([
    'current',
    'in_progress',
    'in_verification'
  ]);

  useEffect(() => {
    incentiveCampaignFind
      .fetch({
        appendToFragment,
        fetchOnMount: true,
        variables: {
          perPage: configPage.pageSize,
          page: pageNumber,
          sort: SortFindManyIncentiveCampaignInput.IdDesc,
          filter: {
            status: statusFilter,
            statusIN: statusINFilter
          }
        }
      })
      .then((ctx) => {
        setItems(ctx.result?.items);
      });
  }, [statusFilter, pageNumber]);
  const isLoading = incentiveCampaignFind.loading;
  const total = incentiveCampaignFind.result?.count;

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <>
            <CampaignSortSelect>
              <Select
                value={statusFilter}
                placeholder="FILTRAR POR:"
                options={filterOptions}
                onChange={(e) => {
                  setStatusINFilter(undefined);
                  setPageNumber(1);
                  setStatusFilter(e);
                }}
              />
            </CampaignSortSelect>

            {!!items?.length ? (
              <>
                <CardIncentiveCampaign
                  defaultImage={'/defaultImages/default-card-thumb.png'}
                  items={items}
                  count={total as number}
                  actions={{
                    accessButton: {
                      label: 'Acessar',
                      action: (campaign) => {
                        history.push(
                          routes.campanhasIncentivo.mount(campaign?._id)
                        );
                      }
                    },
                    share: true,
                    pin: true
                  }}
                />
                <Pagination
                  style={{ textAlign: 'center' }}
                  pageSize={configPage.pageSize}
                  current={pageNumber}
                  total={total!}
                  onChange={(page) => setPageNumber(page)}
                />
              </>
            ) : (
              <NotFound>{configPage.empty}</NotFound>
            )}
          </>
        )}
      </>
    </PageTemplate>
  );
};

const filterOptions = [
  { label: 'Em apuração', value: EnumIncentiveCampaignStatus.InVerification },
  { label: 'Em andamento', value: EnumIncentiveCampaignStatus.InProgress }
];
