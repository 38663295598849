import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Icon, Empty, Result } from 'antd';
import {
  Breadcrumb,
  MaterialProvider,
  useMaterialActions,
  ShareModal,
  Comments
} from '@digi-tim-19/components';
import { Icon as IconAction } from '@digi-tim-19/components/build/main/ActionButton/ActionButton';
import { colors } from '@digi-tim-19/theme';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { Material, Maybe, Category } from '../../autogenerated/client/types';
import { useBreadcrumb } from 'hooks/useBreadcrumb';
import { breadcrumbFragment } from 'utils/breadcrumbFragment';

const PDFButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;

  &,
  a {
    max-height: 30px;
  }
`;

const PDFTitle = styled.p`
  color: ${colors.blue};
  font-family: TimLight;
  font-size: 28px;
  line-height: 26px;
  margin: 20px 20px 20px 0;
`;

const PDFDownload = styled.a`
  align-items: center;
  background: ${(props) => props.theme.blue};
  border-radius: 2px;
  color: ${(props) => props.theme.white} !important;
  display: block;
  margin-right: 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
`;

const PDFViewer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  flex-basis: 100%;
  max-width: 100%;
  max-height: max-content;

  iframe {
    width: 100%;
    min-height: 100vh;
    border: none;
    overflow: auto;
  }
`;

export const PageLoadPDF = () => {
  const { id } = useParams<{ id: string }>();
  const materialFind = useClient('AnalyticsMaterialView');
  const MaterialLikeToggle = useClient('MaterialLikeToggle');
  const actions = useMaterialActions();
  const [material, setMaterial] = useState<Material>();
  const [docCategory, setDocCategory] = useState<Maybe<Category>>();
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');
  const breadcrumb = useBreadcrumb(material?.title!, docCategory!);
  const download = typeof material?.download === "undefined" || material?.download === null || material?.download;

  useEffect(() => {
    materialFind
      .fetch({
        appendToFragment,
        fetchOnMount: true,
        variables: {
          _id: id
        }
      })
      .then((ctx) => {
        setMaterial(ctx.result!);
        setDocCategory(ctx.result?.categories.find((item: any) => item._id));
      });
  }, [id]);

  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={breadcrumb} />}>
      <>
        {material?.files?.map((item) => {
          if (item?.kind === 'card_thumb') return;

          if (item?.extension === 'pdf') {
            const fileSize = item.size || 1;

            return (
              <MaterialProvider material={material as any}>
                <PDFTitle>{material?.title}</PDFTitle>

                {fileSize < 2 * Math.pow(10, 7) ? (
                  <PDFViewer
                    key={item._id}
                  >
                    <iframe src={item?.signedUrl ? `${item?.signedUrl}#toolbar=0` : ''} />
                  </PDFViewer>
                ) : (
                  <Result
                    title="Arquivo muito grande para abrir no visualizador"
                    subTitle="Por favor, faça o download do material no botão abaixo"
                  />
                )}

                <PDFButtons>
                  {material.enableSocialInteraction && (
                    <IconAction
                      isFilled={material.likes?.liked}
                      name={'heart'}
                      amount={material.likes?.count}
                      onClick={() => {
                        MaterialLikeToggle.fetch({
                          variables: { recordId: material._id }
                        });
                      }}
                    />
                  )}
                  <ShareModal />
                  <IconAction
                    isFilled={material.pinned}
                    name={'star'}
                    onClick={() =>
                      material.pinned
                        ? actions.deletePin({ recordId: material._id })
                        : actions.pin({ recordId: material._id })
                    }
                  />
                  {
                    Boolean(download)
                    &&
                    <PDFDownload
                      href={item.signedUrl || '#'}
                      download={material.title}
                      className="download"
                      target="_blank"
                      onClick={() =>
                        handleTrackDownload(
                          material.files?.find((el) => el?.kind !== 'card_thumb')
                            ?._id || ''
                        )
                      }
                    >
                      <Icon
                        type="download"
                        style={{ color: colors.white, marginRight: 5 }}
                      />
                      Download
                    </PDFDownload>
                  }
                  {material.link && (
                    <PDFDownload
                      href={material?.link || '#'}
                      download={material.title}
                      className="download"
                    >
                      <Icon
                        type="link"
                        style={{ color: colors.white, marginRight: 5 }}
                      />
                      Acessar Link
                    </PDFDownload>
                  )}
                </PDFButtons>

                {material.enableSocialInteraction && (
                  <Comments parentId={material._id} />
                )}
              </MaterialProvider>
            );
          }

          if (item?.extension === 'docx') {
            return (
              <MaterialProvider material={material as any}>
                <PDFTitle>{material?.title}</PDFTitle>

                <span>Baixe o arquivo .docx para visualizálo</span>

                <PDFButtons>
                  {material.enableSocialInteraction && (
                    <IconAction
                      isFilled={material.likes?.liked}
                      name={'heart'}
                      amount={material.likes?.count}
                      onClick={() => {
                        MaterialLikeToggle.fetch({
                          variables: { recordId: material._id }
                        });
                      }}
                    />
                  )}
                  <ShareModal />
                  <IconAction
                    isFilled={material.pinned}
                    name={'star'}
                    onClick={() =>
                      material.pinned
                        ? actions.deletePin({ recordId: material._id })
                        : actions.pin({ recordId: material._id })
                    }
                  />

                  <PDFDownload
                    href={item.signedUrl || '#'}
                    download={material.title}
                    className="download"
                  >
                    <Icon
                      type="download"
                      style={{ color: colors.white, marginRight: 5 }}
                    />
                    Download
                  </PDFDownload>

                  {material.link && (
                    <PDFDownload
                      href={material?.link || '#'}
                      download={material.title}
                      className="download"
                    >
                      <Icon
                        type="link"
                        style={{ color: colors.white, marginRight: 5 }}
                      />
                      Acessar Link
                    </PDFDownload>
                  )}
                </PDFButtons>

                {material.enableSocialInteraction && (
                  <Comments parentId={material._id} />
                )}
              </MaterialProvider>
            );
          }

          return <Empty />;
        })}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  enableSocialInteraction
  likes {
    liked
    count
  }
  files {
    _id
    kind
    signedUrl
    downloadUrl
    extension
    size
  }
  categories {
    ${breadcrumbFragment}
  }
`;
