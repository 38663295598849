import { colors } from '@digi-tim-19/theme';
import styled from 'styled-components';

export const Bellicon = styled.button`
  position: relative;
  background: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  flex-shrink: 0;

  .Bellicon.unread {
    color: ${colors.red};
  }

  .red-dot {
    width: 9px;
    height: 9px;
    background-color: ${colors.red};
    border-radius: 50%;
    border: 1px solid ${colors.blue};
    position: relative;
    z-index: 1;

    right: 16px;
    top: 7px;
    transform: translate(50%, -50%);
  }
`;
