import React, { useEffect, useState } from 'react';
import { Modal, Button, Tabs } from 'antd';
import { UseFieldConfig } from 'react-final-form';
import { useField, useForm } from '@digi-tim-19/components';

import { DocsEditorForm } from './DocsEditorForm';
import { DocsEditorFiles } from './DocsEditorFiles';
import { useClient } from 'autogenerated/client/client';
import { uniq } from 'lodash';
import { verdade } from '@digi-tim-19/utils';

const { TabPane } = Tabs;

export type TDocsEditorProps = {
  name?: string;
  fieldConfig?: UseFieldConfig<any>;
};

type FileLink = {
  _id: string;
  title: string;
  extension: string;
  src: string;
  downloadUrl: string;
};

export type TFilesLink = FileLink[];

export const DocsEditor = (props: TDocsEditorProps) => {
  const { name = 'editor', fieldConfig } = props;
  const field = useField<string[]>(name, fieldConfig);
  const formApi = useForm();
  const initialFiles = formApi.getState().values['editorFiles'];
  const isNewEditor = !field.input.value;

  const [visible, setVisible] = useState(false);
  const [isEditorTab, setIsEditorTab] = useState(false);
  const [filesLink, setFilesLink] = useState<TFilesLink>([]);

  const FileFind = useClient('FileFindByIds');
  useEffect(() => {
    if (Array.isArray(initialFiles) && initialFiles.length > 0) {
      const variables = { _ids: uniq(initialFiles), limit: 500 };
      FileFind.fetch({ variables }).then((ctx) => {
        const files = verdade(ctx.result);
        setFilesLink(
          files.map((el) => ({
            _id: el._id,
            title: el.title ?? '',
            extension: el.extension ?? '',
            src: el.url ?? '',
            downloadUrl: el.signedUrl ?? ''
          }))
        );
      });
    }
  }, [initialFiles]);

  return (
    <>
      <Button type="primary" onClick={() => setVisible(!visible)}>
        {isNewEditor ? 'Cadastro de' : 'Continuar em'} HTML
      </Button>

      <Modal
        width={'85vw'}
        visible={visible}
        onCancel={() => setVisible(!visible)}
        onOk={() => setVisible(!visible)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Tabs onChange={(key) => setIsEditorTab(key === 'editor')}>
          <TabPane tab="Upload" key="upload">
            <DocsEditorFiles
              initialFiles={filesLink}
              onChange={(files) => formApi.change('editorFiles', files)}
            />
          </TabPane>
          <TabPane tab="Editor" key="editor">
            {isEditorTab && (
              <DocsEditorForm
                files={filesLink}
                initialValues={field.input.value}
                onChange={(data) => field.input.onChange(data)}
              />
            )}
          </TabPane>
          <TabPane tab="Preview" key="preview">
            {!isEditorTab && (
              <DocsEditorForm
                files={[]}
                initialValues={field.input.value}
                onChange={() => {}}
                previewMode={true}
              />
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
