import React from 'react';
import styled from 'styled-components';
import { TypeMenuItem } from '../PageTemplate';
import { SubMenuItem } from './SubMenuItem';
import { colors } from '@digi-tim-19/theme';
import { Icon } from 'antd';

export type TypeSubMenu = {
  redirecionarLink: Function;
  selectedItem: TypeMenuItem;
  menuIsOpen?: boolean;
  setSubMenuIsOpen?: Function;
  subMenuIsOpen?: boolean;
};

const SubMenuWrapper = styled.div<{ subMenuOpen?: boolean; menuOpen: any }>`
  display: ${({ subMenuOpen }) => (subMenuOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: ${({ menuOpen }) =>
    menuOpen ? 'calc(100vw - 72px)' : 'calc(100vw - 296px)'}%;
  padding: 16px 0px 16px 16px;
  overflow-y: auto;

  .subMenuCategoryName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-bottom: solid 1px ${colors.blue};
    padding-top: 30px;
    max-height: 85px;

    & .title {
      margin: 0;
      color: #000;
      font-size: 20px;
      font-weight: 700;
      padding: 8px 0 16px 0;
      font-family: TimBold;
    }
  }
`;

const SubMenuContent = styled.div<{ menuOpen?: boolean }>`
  display: flex;
  width: ${({ menuOpen }) =>
    menuOpen ? 'calc(100vw - 102px)' : 'calc(100vw - 327px)'};
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
  padding-top: 32px;

  @media (max-width: 1024px) {
    width: ${({ menuOpen }) =>
      menuOpen ? 'calc(100vw - 327px)' : 'calc(100vw - 102px)'};
  }
`;

export const SubMenu = ({
  selectedItem,
  redirecionarLink,
  menuIsOpen,
  subMenuIsOpen,
  setSubMenuIsOpen
}: TypeSubMenu) => {
  return (
    <SubMenuWrapper
      subMenuOpen={subMenuIsOpen}
      menuOpen={menuIsOpen}
    >
      <div className="subMenuCategoryName">
        <h1 className="title">{selectedItem.text.toUpperCase()}</h1>
        <Icon
          onClick={() => setSubMenuIsOpen && setSubMenuIsOpen()}
          type="close-circle"
          style={{
            color: "black",
            fontSize: "20px",
            cursor: "pointer"
          }}
        />
      </div>
      <SubMenuContent menuOpen={menuIsOpen}>
        {selectedItem && (
          <SubMenuItem
            selectedItem={selectedItem}
            redirecionarLink={redirecionarLink}
          />
        )}
      </SubMenuContent>
    </SubMenuWrapper>
  );
};
