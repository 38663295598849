import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { AutoComplete, Input, Select } from 'antd';
import { fetchGraphql } from 'graphql-clientgen';
import { useClientStore, verdade } from '@digi-tim-19/components';

const { Option } = Select;

const SearchInputWrapper = styled.div`
  width: 100%;
  form {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    form {
      margin: 0 auto;
    }
  }

  .ant-input-affix-wrapper .ant-input-suffix {
    top: 19px;
    left: 8px;
    font-size: 15px;
    cursor: text;
  }

  .ant-input {
    padding-left: 30px;
    font-size: 14px;
    font-family: TimRegular;
    color: #706f6f;
    font-weight: 500;
  }

  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .juFOqX {
      margin-top: 0;
      padding: 20px;
    }
  }
`;

function useAutoComplete() {
  const timerRef = React.useRef<any>(null);
  const termRef = React.useRef('');

  const searchApi = fetchGraphql('MaterialSearch', {}, useClientStore());

  const resultMap = React.useMemo(() => {
    return new Map<string, string[]>();
  }, []);

  const [result, setResult] = React.useState<string[]>([]);

  function trigger() {
    const existing = resultMap.get(termRef.current);
    if (existing) {
      setResult(existing.filter(Boolean));
    }
  }

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return {
    result: result,
    setTerm: function autocompleteSearch(term: string) {
      if (!term) return;

      termRef.current = term;
      clearTimeout(timerRef.current);

      // if (resultMap.get(term)) {
      //   return trigger();
      // }

      const run = () => {
        searchApi({
          variables: { search: term }
        }).then((ctx) => {
          if (ctx.result) {
            resultMap.set(
              term,
              [term].concat(verdade(ctx.result.autocomplete)).filter(Boolean)
            );
            trigger();
          }
        });
      };

      let timeout = 200;

      if (term.length < 4) {
        timeout = 100;
      }

      timerRef.current = setTimeout(run, timeout);
    }
  };
}

export const SearchInput = (props: TSearchInputProps) => {
  const { className } = props;

  const valueRef = React.useRef('');
  const api = useAutoComplete();

  const removeSpacesOfString = (str: String) => str.replace(/\s/g, '');

  const searchNotDuplicated = (array: any) => {
    return array.reduce((acc: any, item: String) => {
      if (
        acc
          .map((i: any) => removeSpacesOfString(i).toLowerCase())
          .includes(removeSpacesOfString(item).toLowerCase())
      ) {
        return acc;
      }
      return [...acc, item];
    }, []);
  };

  return (
    <SearchInputWrapper
      className={cx(className, 'ui-flex SearchInput SearchInput_Wrapper')}
    >
      <form
        className={'ui-flex'}
        onSubmit={(ev) => {
          ev.preventDefault();
          if (valueRef.current) {
            props.onSelect?.(valueRef.current);
          }
        }}
      >
        <AutoComplete
          className="global-search"
          size="large"
          /* style={{ width: '100%' }} */
          dataSource={searchNotDuplicated(api.result).map((item: any) => {
            return (
              <Option key={item}>
                <div className="global-search-item">
                  <span className="global-search-item-desc">{item}</span>
                </div>
              </Option>
            );
          })}
          onSelect={(term) => {
            if (typeof term === 'string') {
              props.onSelect?.(term);
            }
          }}
          onSearch={(term) => {
            api.setTerm(term);
          }}
          onChange={(value) => {
            if (typeof value === 'string' && value.length <= 110) {
              valueRef.current = value;
            }
          }}
          optionLabelProp="text"
        >
          <Input.Search placeholder="Buscar no Pra Conectar" />
        </AutoComplete>
      </form>
    </SearchInputWrapper>
  );
};

// export default SearchInput;

export type TSearchInputProps = {
  className?: string;
  children?: React.ReactNode;
  onSelect?: (term: string) => any;
};
