import React from 'react';
import { message } from 'antd';
import { useParams } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  History,
  useCurrentUser
} from '@digi-tim-19/components';

import { Layout } from '../_layout';
import { Form } from '../Form';
import { useClient } from '../../../autogenerated/client/client';
import { useMaterial } from '../../../hooks/materials/useMaterial';
import { routes } from '../../../config/routes';

import { NotificationsWrapper } from '../Cadastrar/stylesCadastrar';
import { PageTemplate } from 'components/Layout/PageTemplate';

export const EditarNotificacao = () => {
  const { id } = useParams();
  const { result: currentUser } = useCurrentUser();

  const notificacaoFind = useMaterial(id!, appendToFragment);
  const notificacaoApprove = useClient('CommunicationApproveChannel');
  const notificacaoDisapprove = useClient('CommunicationDisapproveChannel');
  const notificacaoRemoveApprover = useClient('CommunicationRemoveApprover');

  const notificacaoResult = notificacaoFind.result;

  const isUserAdmin = () => {
    const admRoleGroup = [
      'corporate:adm_trade_corporate',
      'residencial:adm_trade_residencial'
    ];
    const currentUserRoleGroup = currentUser?.roleGroup?._id || '';

    return admRoleGroup.includes(currentUserRoleGroup);
  };

  const canUpdateStatus = () => {
    const { approvedBy, awaitingApprovers } = notificacaoResult!;

    const hasApproved = approvedBy?.includes(currentUser?._id);
    const userApprover = awaitingApprovers?.includes(currentUser?._id);
    const approverRole = currentUser?.roles.includes('update_status_material');

    if (!hasApproved && userApprover && approverRole) {
      return true;
    }
    return false;
  };

  const configPage = {
    pageName: 'Notificação',
    icon: 'bell',
    description: 'Edição de notificação.',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo', link: routes.home.mount() },
      { title: 'Comunicação', link: routes.home.mount() },
      { title: 'Notificação', link: routes.notificacoesGerenciar.mount() },
      { title: 'Editar', link: routes.notificacoesEditar.mount(id!) }
    ]
  };

  return (
    <PageTemplate breadcrumb={<Breadcrumb items={configPage.breadcrumb} />}>
      <NotificationsWrapper>
        <TitleDescription
          iconType={configPage.icon}
          title={configPage.pageName}
          description={configPage.description}
        />

        <h3>Editar Notificação</h3>

        {notificacaoResult && (
          <>
            <ProviderSelecionarPublico>
              <Form initialValues={notificacaoResult} />
            </ProviderSelecionarPublico>

            <History
              item={{
                status: notificacaoResult.status || '',
                history: notificacaoResult.history?.items || [],
                awaitingApprovers:
                  notificacaoResult.awaitingUsersApprover || [],
                currentUserId: currentUser?._id || ''
              }}
              isUserAdmin={isUserAdmin()}
              canUpdateStatus={canUpdateStatus()}
              onApproved={() => {
                const variables = { recordId: notificacaoResult?._id };

                notificacaoApprove
                  .fetch({
                    variables,
                    afterMutate: /^Material/
                  })
                  .then(() => {
                    message.success('Aprovada com sucesso!');
                  });
              }}
              onDisapproved={(disapprovalMessage) => {
                const variables = {
                  recordId: notificacaoResult?._id,
                  message: disapprovalMessage || ''
                };

                notificacaoDisapprove
                  .fetch({
                    variables,
                    afterMutate: /^Material/
                  })
                  .then(() => {
                    message.success('Notificação reprovada!');
                  });
              }}
              onRemoveUser={(userId) => {
                if (userId) {
                  const variables = {
                    communicationId: notificacaoResult?._id,
                    userId
                  };

                  notificacaoRemoveApprover
                    .fetch({
                      variables,
                      afterMutate: /^Material/
                    })
                    .then((ctx) => {
                      if (ctx.result)
                        message.success('Usuário removido do fluxo!');
                    });
                }
              }}
            />
          </>
        )}
      </NotificationsWrapper>
    </PageTemplate>
  );
};

const appendToFragment = `
  availableAtRegions
  availableAtChannels
  availableAtRoleGroups
  approvedBy
  title
  description
  fileIds
  tags

  author { name }
  status { value }
  files {
    _id
    title
    kind
    downloadUrl
  }
  validity {
    start
    end
  }
  categories {
    _id
    name
    parentId
  }
  awaitingApprovers
  awaitingUsersApprover {
    _id
    name
  }
  history {
    items {
      author { name }
      date
      updates
    }
  }
`;
