import React from 'react';
import { Switch, Route, Router } from 'react-router';
import { GraphQLClient, GraphQLProvider } from 'graphql-clientgen';
import {
  ThemeProvider,
  graphqlMiddleware,
  routerPageWrapper,
  setAnalyticsConfig,
  DataProviders,
  NotFound,
  graphqlLogsMiddleware
} from '@digi-tim-19/components';
import { SocketIOProvider, UserSession } from '@digi-tim-19/utils';
import { createBrowserHistory } from 'history';

import { AppMethods, AppMethodsInfo } from './autogenerated/client/client';
import { API_DOMAIN_URL, API_URL } from './config/apiURL';

// pages
import { Home } from './pages/Home/Home';
import { Favoritos } from './pages/Favoritos/Favoritos';
import { AtividadesRecentes } from './pages/AtividadesRecentes/AtividadesRecentes';
import { Notificacoes } from './pages/Notificacoes/Notificacoes';
import { LinksUteis } from './pages/LinksUteis/LinksUteis';

import { routes } from './config/routes';
import { siteTheme } from './styles/theme';

import { Login } from './pages/Login/Login';
import { LoginCPF } from './pages/LoginCPF/LoginCPF';
import { AlterarSenha } from './pages/LoginCPF/AlterarSenha';
import { Busca } from './pages/Busca/Busca';

import { Documentos } from './pages/Documentos/Documentos';
import { DocumentosListar } from './pages/Documentos/Listar/Listar';
import { DocumentosCadastrar } from './pages/Documentos/Cadastrar/Cadastrar';
import { DocumentosEditar } from './pages/Documentos/Editar/Editar';

import { Eventos } from './pages/Eventos/Eventos';
import { EventosListar } from './pages/Eventos/Listar/Listar';
import { EventosComponentes } from './pages/Eventos/Listar/Componentes';
import { EventosCadastrar } from './pages/Eventos/Cadastrar/Cadastrar';
import { EventosEditar } from './pages/Eventos/Editar/Editar';
import { EventosEditarComponents } from './pages/Eventos/EditarComponentes/Editar';
import { PageLoadEventView } from './pages/Eventos/Visualizar/Evento';

import { ListarNotificacoes } from './pages/Notificacoes/Listar/Listar';
import { EditarNotificacao } from './pages/Notificacoes/Editar/Editar';
import { CadastrarNotificacao } from './pages/Notificacoes/Cadastrar/Cadastrar';

import { Informativos } from './pages/Informativos/Informativos';
import { VisualizacaoInformativo } from './pages/Informativos/Visualizacao/VisualizacaoInformativo';
import { InformativosListar } from './pages/Informativos/Listar/Listar';
import { InformativosEditar } from './pages/Informativos/Editar/Editar';
import { InformativosCadastrar } from './pages/Informativos/Cadastrar/Cadastrar';

import { CampanhaIncentivo } from './pages/CampanhaIncentivo/CampanhaIncentivo';
import { CampanhaIncentivoVigentes } from './pages/CampanhaIncentivo/CampanhaIncentivoVigentes';
import { CampanhaIncentivoEncerradas } from './pages/CampanhaIncentivo/CampanhaIncentivoEncerradas';
import { CampanhaIncentivoResultados } from './pages/CampanhaIncentivo/CampanhaIncentivoResultados';
import { CampanhaIncentivoListar } from './pages/CampanhaIncentivo/Listar/Listar';
import { CampanhaIncentivoEditar } from './pages/CampanhaIncentivo/Editar/Editar';
import { CampanhaIncentivoCadastrar } from './pages/CampanhaIncentivo/Cadastrar/Cadastrar';
import { CampanhaEstornoUploadExtraListar } from './pages/CampanhaEstornoUploadExtra/Listar/Listar';
import { CampanhaEstornoUploadExtra } from 'pages/CampanhaEstornoUploadExtra/Cadastrar/Cadastrar';

import { OrdemPagamentoEditar } from './pages/OrdemPagamento/Editar/Editar';
import { OrdemPagamentoListar } from './pages/OrdemPagamento/Listar/Listar';
import { OrdemPagamentoCadastrar } from './pages/OrdemPagamento/Cadastrar/Cadastrar';

import { PageLoadPDF } from './pages/PageLoadPDF/PageLoadPDF';
import { PageLoadHTML } from './pages/PageLoadHTML/PageLoadHTML';
import { PageLoadImage } from './pages/PageLoadImage/PageLoadImage';
import { PageLoadFiles } from 'pages/PageLoadFiles/PageLoadFiles';

import { LinksUteisListar } from './pages/LinksUteis/Listar/Listar';
import { LinksUteisEditar } from './pages/LinksUteis/Editar/Editar';
import { LinksUteisCadastrar } from './pages/LinksUteis/Cadastrar/Cadastrar';

import { OnTube } from './pages/OnTube/OnTube';
import { OnTubePlayer } from './pages/OnTube/OnTubePlayer';
import { OnTubePlaylist } from './pages/OnTube/OnTubePlaylist';
import { OnTubeListar } from './pages/OnTube/Listar/Listar';
import { OnTubeCadastrar } from './pages/OnTube/Cadastrar/Cadastrar';
import { OnTubeEditar } from './pages/OnTube/Editar/Editar';

import { Noticias } from './pages/Noticias/Noticias';
import { ConteudoNoticia } from './pages/Noticias/ConteudoNoticia';
import { NoticiasListar } from './pages/Noticias/Listar/Listar';
import { NoticiasEditar } from './pages/Noticias/Editar/Editar';
import { NoticiasCadastrar } from './pages/Noticias/Cadastrar/Cadastrar';

import { ContestacaoEditar } from 'pages/CampanhaContestacao/Editar/Editar';
import { ContestacaoListar } from 'pages/CampanhaContestacao/Listar/Listar';

import { Dashboard } from './pages/Relatorios/Dashboard';
import { RelatorioUsuarios } from './pages/Relatorios/RelatorioUsuarios';
import { RelatorioPontosExpirados } from './pages/Relatorios/RelatorioPontosExpirados';

import { BannerListar } from './pages/Home/Banners/Listar/Listar';
import { BannerCadastrar } from './pages/Home/Banners/Cadastrar/Cadastrar';
import { BannerEditar } from './pages/Home/Banners/Editar/Editar';

import { UploadPontos } from './pages/UploadPontos/Cadastrar/Cadastrar';

import { UsuariosAprovadores } from './pages/User/Aprovadores/Aprovadores';
import { UsuariosListar } from './pages/User/Listar/Listar';

import { InformacoesExtrato } from './pages/InformacoesExtrato/InformacoesExtrato';

import { Aprovacoes } from './pages/Aprovacoes/Aprovacoes';

import { Pesquisa } from './pages/Pesquisa/Pesquisa';
import { PesquisaCadastrar } from './pages/Pesquisa/Cadastrar/Cadastrar';
import { PesquisaListar } from './pages/Pesquisa/Listar/Listar';

import { DownloadIncentivo } from './pages/CampanhaRelatorios/DownloadIncentivo';
import { Chat } from './pages/Chat/Chat';
import { Helmet } from 'react-helmet';

import { EventosModeracaoListar } from 'pages/Eventos/Moderacao';
import { EventosModeracaoEditar } from 'pages/Eventos/Moderacao/Editar';
import { Unsubscribe } from 'pages/Unsubscribe';
import { MeuDiaNoPdv } from 'pages/MeuDiaNoPdv/MeuDianoPdv';
import { MyContestations } from 'pages/CampanhaContestacao/Listar/MyContestations';
import { FormMyContestation } from 'pages/CampanhaContestacao/FormMyContestation';
import { GerenciarResearchList } from 'pages/Research/Listar/GerenciarResearchList';
import { GerenciarResearchCreate } from 'pages/Research/Form/Create';
import { GerenciarResearchEdit } from 'pages/Research/Form/Edit';
import { ResearchList } from 'pages/Research/Listar/ResearchList';
import { ResearchAnswer } from 'pages/Research/Form/Answer';
import { GerenciarAnswerList } from 'pages/Research/Listar/GerenciarAnswerList';
import { GerenciarAnswerUserList } from 'pages/Research/Listar/GerenciarAnswerUserList';
import { RemuneracoesListar } from 'pages/Remuneracoes/Listar/Listar';
import { RemuneracoesCadastrar } from 'pages/Remuneracoes/Cadastrar/Cadastrar';
import { RemuneracoesEditar } from 'pages/Remuneracoes/Editar/Editar';
import { Remuneracoes } from 'pages/Remuneracoes/Remuneracoes';
import { RemuneracaoVisualizar } from 'pages/Remuneracoes/RemuneracaoVisualizar';
import { PlaylistsListar } from 'pages/Playlists/Listar/Listar';
import { PlaylistCadastrar } from 'pages/Playlists/Cadastrar/Cadastrar';
import { PlaylistEditar } from 'pages/Playlists/Editar/Editar';

setAnalyticsConfig({
  app: 'site-corp',
  containerId: 'GTM-NTMLQMX',
  extraGoogleProps: {}
});

export const App = () => {
  const isLocalHost = window.location.host.match(/localhost:/);

  const [graphqlClient] = React.useState(() => {
    return new GraphQLClient({
      url: API_URL,
      middleware: [
        graphqlLogsMiddleware,
        graphqlMiddleware,
        async function (ctx) {
          if (Boolean(ctx.fetchResponse?.statusText === 'Unauthorized')) {
            UserSession.clear();
          }
          return ctx;
        },
        async function (ctx) {
          if (ctx.requestConfig) {
            // utilizado para auth com pcs e captutar cookie do analytics
            ctx.requestConfig.credentials = isLocalHost ? 'omit' : 'include';
          }
          return ctx;
        }
      ],
      methods: AppMethods,
      methodsInfo: AppMethodsInfo
    });
  });

  return (
    <ThemeProvider theme={siteTheme}>
      <Helmet>
        {/* <meta http-equiv="Content-Security-Policy" content="script-src 'self'; default-src 'self';base-uri 'self';block-all-mixed-content;font-src 'self' https: data:;frame-ancestors 'self';img-src 'self' data:;object-src 'none';script-src-attr 'none';style-src 'self' https: 'unsafe-inline';upgrade-insecure-requests'" /> */}
        <meta
          http-equiv="X-Content-Type-Options"
          content="max-age=15552000; includeSubDomains"
        />
        <meta http-equiv="Strict-Transport-Security" content="nosniff" />
        <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
      </Helmet>
      <SocketIOProvider
        url={API_DOMAIN_URL}
        opts={{
          transports: ['websocket'],
          path: `/socket.io`
        }}
      >
        <GraphQLProvider
          client={graphqlClient}
          render={(store) => {
            return (
              <DataProviders
                store={store}
                uploadUrl={API_DOMAIN_URL}
                parseUserPermissions={(user) => {
                  const roleGroup = user.roleGroup?._id;

                  const isCorpAdmin =
                    roleGroup?.includes('adm_trade') ||
                    roleGroup?.includes('internos_') ||
                    roleGroup?.includes('operacao_residencial') ||
                    roleGroup?.includes('operacao_corporate');

                  return {
                    manageOtherUsersComments: isCorpAdmin,
                    simulateProfiles: user.roles?.some(
                      (el) => el === 'update_status_material'
                    )
                  };
                }}
              >
                <Routes />
              </DataProviders>
            );
          }}
        />
      </SocketIOProvider>
    </ThemeProvider>
  );
};

const history = createBrowserHistory();

const Routes = () => {
  //
  React.useEffect(() => {
    history.listen(function () {
      window.scroll(0, 0);
    });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={routes.unsubscribe.path}
          exact
          component={routerPageWrapper(Unsubscribe)}
        />
        <Route
          path={routes.login.path}
          exact
          component={routerPageWrapper(Login)}
        />
        <Route path={routes.meuDiaNoPdv.path} exact component={MeuDiaNoPdv} />
        <Route path={routes.loginCPF.path} exact component={LoginCPF} />
        <Route
          path={routes.changePassword.path}
          exact
          component={routerPageWrapper(AlterarSenha)}
        />
        <Route
          path={routes.home.path}
          exact
          component={routerPageWrapper(Home)}
        />
        <Route
          path={routes.homePortal.path}
          exact
          component={routerPageWrapper(Home)}
        />

        <Route
          path={routes.favoritos.path}
          exact
          component={routerPageWrapper(Favoritos)}
        />
        <Route
          path={routes.noticias.path}
          exact
          component={routerPageWrapper(Noticias)}
        />
        <Route
          path={routes.noticiasConteudo.path}
          exact
          component={routerPageWrapper(ConteudoNoticia)}
        />
        <Route
          path={routes.atividadesRecentes.path}
          exact
          component={routerPageWrapper(AtividadesRecentes)}
        />
        <Route
          path={routes.notificacoes.path}
          exact
          component={routerPageWrapper(Notificacoes)}
        />
        <Route
          path={routes.linksUteis.path}
          exact
          component={routerPageWrapper(LinksUteis)}
        />

        {/* ***** PageLoad ***** */}
        <Route
          path={routes.pdfViewer.path}
          exact
          component={routerPageWrapper(PageLoadPDF)}
        />
        <Route
          path={routes.htmlViewer.path}
          exact
          component={routerPageWrapper(PageLoadHTML)}
        />
        <Route
          path={routes.imageViewer.path}
          exact
          component={routerPageWrapper(PageLoadImage)}
        />
        <Route
          path={routes.filesViewer.path}
          exact
          component={routerPageWrapper(PageLoadFiles)}
        />
        <Route
          path={routes.informacoesExtrato.path}
          exact
          component={routerPageWrapper(InformacoesExtrato)}
        />
        <Route
          path={routes.search.path}
          exact
          component={routerPageWrapper(Busca)}
        />
        <Route
          path={routes.linksUteisListar.path}
          exact
          component={routerPageWrapper(LinksUteisListar)}
        />
        <Route
          path={routes.informativos.path}
          exact
          component={routerPageWrapper(Informativos)}
        />
        <Route
          path={routes.informativosResidencial.path}
          exact
          component={routerPageWrapper(Informativos)}
        />
        <Route
          path={routes.informativosListar.path}
          exact
          component={routerPageWrapper(InformativosListar)}
        />

        {/*
          Campanhas
        */}
        <Route
          path={routes.campanhasIncentivo.path}
          exact
          component={routerPageWrapper(CampanhaIncentivo)}
        />
        <Route
          path={routes.campanhasIncentivoVigentes.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoVigentes)}
        />
        <Route
          path={routes.campanhasIncentivoEncerradas.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoEncerradas)}
        />
        <Route
          path={routes.campanhasIncentivoResultados.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoResultados)}
        />
        <Route
          path={routes.campanhasIncentivoListar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoListar)}
        />
        <Route
          path={routes.campanhasIncentivoEditar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoEditar)}
        />
        <Route
          path={routes.campanhasIncentivoCadastrar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoCadastrar)}
        />
        <Route
          path={routes.campanhasIncentivoUploadPontos.path}
          exact
          component={routerPageWrapper(UploadPontos)}
        />
        <Route
          path={routes.ordemPagamentoCadastrar.path}
          exact
          component={routerPageWrapper(OrdemPagamentoCadastrar)}
        />
        <Route
          path={routes.ordemPagamentoListar.path}
          exact
          component={routerPageWrapper(OrdemPagamentoListar)}
        />
        <Route
          path={routes.ordemPagamentoEditar.path}
          exact
          component={routerPageWrapper(OrdemPagamentoEditar)}
        />
        <Route
          path={routes.campanhasIncentivoEstornoUploadExtraListar.path}
          exact
          component={routerPageWrapper(CampanhaEstornoUploadExtraListar)}
        />
        <Route
          path={routes.campanhasIncentivoEstornoUploadExtra.path}
          exact
          component={routerPageWrapper(CampanhaEstornoUploadExtra)}
        />

        <Route
          path={routes.documentos.path}
          exact
          component={routerPageWrapper(Documentos)}
        />
        <Route
          path={routes.documentosSubCategoria.path}
          exact
          component={routerPageWrapper(Documentos)}
        />
        <Route
          path={routes.documentosSubSubCategoria.path}
          exact
          component={routerPageWrapper(Documentos)}
        />
        <Route
          path={routes.documentosCategoria.path}
          exact
          component={routerPageWrapper(Documentos)}
        />
        <Route
          path={routes.documentosMenu.path}
          exact
          component={routerPageWrapper(Documentos)}
        />
        <Route
          path={routes.documentosGerenciar.path}
          exact
          component={routerPageWrapper(DocumentosListar)}
        />
        <Route
          path={routes.visualizarInformativo.path}
          exact
          component={routerPageWrapper(VisualizacaoInformativo)}
        />
        <Route
          path={routes.informativosEditar.path}
          exact
          component={routerPageWrapper(InformativosEditar)}
        />
        <Route
          path={routes.informativosCadastrar.path}
          exact
          component={routerPageWrapper(InformativosCadastrar)}
        />
        <Route
          path={routes.linksUteisEditar.path}
          exact
          component={routerPageWrapper(LinksUteisEditar)}
        />
        <Route
          path={routes.linksUteisCadastrar.path}
          exact
          component={routerPageWrapper(LinksUteisCadastrar)}
        />
        <Route
          path={routes.noticiasListar.path}
          exact
          component={routerPageWrapper(NoticiasListar)}
        />
        <Route
          path={routes.noticiasCadastrar.path}
          exact
          component={routerPageWrapper(NoticiasCadastrar)}
        />
        <Route
          path={routes.noticiasEditar.path}
          exact
          component={routerPageWrapper(NoticiasEditar)}
        />
        <Route
          path={routes.videos.path}
          exact
          component={routerPageWrapper(OnTube)}
        />
        <Route
          path={routes.videosPlayer.path}
          exact
          component={routerPageWrapper(OnTubePlayer)}
        />
        <Route
          path={routes.videosPlaylist.path}
          exact
          component={routerPageWrapper(OnTubePlaylist)}
        />
        <Route
          path={routes.videosPlaylistListar.path}
          exact
          component={routerPageWrapper(PlaylistsListar)}
        />
        <Route
          path={routes.videosPlaylistCadastrar.path}
          exact
          component={routerPageWrapper(PlaylistCadastrar)}
        />
        <Route
          path={routes.videosPlaylistEditar.path}
          exact
          component={routerPageWrapper(PlaylistEditar)}
        />
        <Route
          path={routes.videosListar.path}
          exact
          component={routerPageWrapper(OnTubeListar)}
        />
        <Route
          path={routes.videosCadastrar.path}
          exact
          component={routerPageWrapper(OnTubeCadastrar)}
        />
        <Route
          path={routes.videosEditar.path}
          exact
          component={routerPageWrapper(OnTubeEditar)}
        />
        <Route
          path={routes.notificacoesGerenciar.path}
          exact
          component={ListarNotificacoes}
        />
        <Route
          path={routes.notificacoesEditar.path}
          exact
          component={EditarNotificacao}
        />
        <Route
          path={routes.notificacoesCadastrar.path}
          exact
          component={CadastrarNotificacao}
        />
        <Route
          path={routes.documentosCadastrar.path}
          exact
          component={routerPageWrapper(DocumentosCadastrar)}
        />
        <Route
          path={routes.documentosEditar.path}
          exact
          component={routerPageWrapper(DocumentosEditar)}
        />
        <Route
          path={routes.contestacaoEditar.path}
          exact
          component={routerPageWrapper(ContestacaoEditar)}
        />
        <Route
          path={routes.contestacaoListar.path}
          exact
          component={routerPageWrapper(ContestacaoListar)}
        />
        <Route
          path={routes.minhasContestacoesListar.path}
          exact
          component={routerPageWrapper(MyContestations)}
        />
        <Route
          path={routes.minhaContestacaoVisualizar.path}
          exact
          component={routerPageWrapper(FormMyContestation)}
        />
        <Route
          path={routes.relatoriosDashboard.path}
          exact
          component={routerPageWrapper(Dashboard)}
        />
        <Route
          path={routes.relatoriosUsuarios.path}
          exact
          component={routerPageWrapper(RelatorioUsuarios)}
        />
        <Route
          path={routes.relatoriosPontosExpirados.path}
          exact
          component={routerPageWrapper(RelatorioPontosExpirados)}
        />
        <Route
          path={routes.bannersListar.path}
          exact
          component={routerPageWrapper(BannerListar)}
        />
        <Route
          path={routes.bannersCadastrar.path}
          exact
          component={routerPageWrapper(BannerCadastrar)}
        />
        <Route
          path={routes.bannersEditar.path}
          exact
          component={routerPageWrapper(BannerEditar)}
        />
        <Route
          path={routes.usuariosAprovadores.path}
          exact
          component={routerPageWrapper(UsuariosAprovadores)}
        />
        <Route
          path={routes.usuariosListar.path}
          exact
          component={routerPageWrapper(UsuariosListar)}
        />
        <Route
          path={routes.aprovacoes.path}
          exact
          component={routerPageWrapper(Aprovacoes)}
        />
        <Route
          path={routes.pesquisa.path}
          exact
          component={routerPageWrapper(Pesquisa)}
        />
        <Route
          path={routes.pesquisaListar.path}
          exact
          component={routerPageWrapper(PesquisaListar)}
        />
        <Route
          path={routes.pesquisaCadastrar.path}
          exact
          component={routerPageWrapper(PesquisaCadastrar)}
        />
        <Route
          path={routes.downloadIncentivo.path}
          exact
          component={routerPageWrapper(DownloadIncentivo)}
        />
        <Route
          path={routes.chat.path}
          exact
          component={routerPageWrapper(Chat)}
        />

        {/*
          Eventos
        */}

        <Route
          path={routes.eventos.path}
          exact
          component={routerPageWrapper(Eventos)}
        />
        <Route
          path={routes.eventosGerenciar.path}
          exact
          component={routerPageWrapper(EventosListar)}
        />
        <Route
          path={routes.eventosCadastrar.path}
          exact
          component={routerPageWrapper(EventosCadastrar)}
        />
        <Route
          path={routes.eventosEditar.path}
          exact
          component={routerPageWrapper(EventosEditar)}
        />

        <Route
          path={routes.eventViewer.path}
          exact
          component={routerPageWrapper(PageLoadEventView)}
        />
        <Route
          path={routes.eventosComponentes.path}
          exact
          component={routerPageWrapper(EventosComponentes)}
        />
        <Route
          path={routes.eventosComponentesEditar.path}
          exact
          component={routerPageWrapper(EventosEditarComponents)}
        />
        <Route
          path={routes.eventosModeracao.path}
          exact
          component={routerPageWrapper(EventosModeracaoListar)}
        />
        <Route
          path={routes.eventosModeracaoEditar.path}
          exact
          component={routerPageWrapper(EventosModeracaoEditar)}
        />
        <Route
          path={routes.researchGerenciar.path}
          exact
          component={routerPageWrapper(GerenciarResearchList)}
        />
        <Route
          path={routes.researchCadastrar.path}
          exact
          component={routerPageWrapper(GerenciarResearchCreate)}
        />
        <Route
          path={routes.researchEditar.path}
          exact
          component={routerPageWrapper(GerenciarResearchEdit)}
        />
        <Route
          path={routes.researchRespostas.path}
          exact
          component={routerPageWrapper(GerenciarAnswerList)}
        />
        <Route
          path={routes.researchRespostasUsuarios.path}
          exact
          component={routerPageWrapper(GerenciarAnswerUserList)}
        />
        <Route
          path={routes.researchListar.path}
          exact
          component={routerPageWrapper(ResearchList)}
        />
        <Route
          path={routes.researchResponder.path}
          exact
          component={routerPageWrapper(ResearchAnswer)}
        />
        <Route
          path={routes.remuneracaoVariavelMetasListar.path}
          exact
          component={routerPageWrapper(RemuneracoesListar)}
        />
        <Route
          path={routes.remuneracaoVariavelMetasCadastrar.path}
          exact
          component={routerPageWrapper(RemuneracoesCadastrar)}
        />
        <Route
          path={routes.remuneracaoVariavelMetasEditar.path}
          exact
          component={routerPageWrapper(RemuneracoesEditar)}
        />
        <Route
          path={routes.remuneracaoVariavelMetasParticipanteListar.path}
          exact
          component={routerPageWrapper(Remuneracoes)}
        />
        <Route
          path={routes.remuneracaoVariavelMetasParticipanteVisualizar.path}
          exact
          component={routerPageWrapper(RemuneracaoVisualizar)}
        />

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
