import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Empty, Icon } from 'antd';
import { LoadingIndicator } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { useInformativeViewer } from './VisualizacaoInformativoHook';
import { SwitchTemplate } from './SwitchTemplate';
import { getRouterByMaterialCorp } from '@digi-tim-19/utils';
import { routes } from 'config/routes';
import { useClient } from 'autogenerated/client/client';

const PageContent = styled.div`
  margin: auto;
`;

const Paragrafo = styled.p`
  margin-left: 10px;
  font-size: 30px;
  font-family: TimLight;
  margin-bottom: 20px;
  margin-top: 20px;
  color: rgb(0, 80, 140);
`;

const LinkAttacment = styled.a`
  margin-top: 10px;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const VisualizacaoInformativo = () => {
  const { id }: any = useParams();
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');
  const {
    titleMaterial,
    templateName,
    templateProps,
    downloadUrlAttachment,
    isLoading,
    isError,
    html,
    files
  } = useInformativeViewer(id || '');

  const handleTrackDownload = (fileId: string | undefined) =>
    apiAnalyticsDownload.fetch({ variables: { fileId } });

  return (
    <PageTemplate>
      <>
        {isLoading && <LoadingIndicator />}
        {!isLoading && isError && <Empty />}

        {templateProps && !isLoading && (
          <PageContent>
            <Paragrafo>{titleMaterial}</Paragrafo>

            <SwitchTemplate
              templateName={templateName}
              templateProps={templateProps}
              html={html}
            />

            {
              Boolean(files?.length > 0)
              &&
              <React.Fragment>
                <Container>
                {
                  files.map((el) => {
                    const routesMaterial = getRouterByMaterialCorp(el, routes);
                    const { routerAccess, routerDownload } = routesMaterial;

                    return (
                      <Item>
                        <a
                          target="_blank"
                          href={routerDownload ? routerDownload : `${process.env.PUBLIC_URL}${routerAccess}`}
                          onClick={() => {
                            if(routerDownload && el._id) handleTrackDownload(el._id)
                          }}
                        >
                          <Icon
                            type="inbox"
                            style={{ marginRight: '5px' }}
                          />
                          {el.title}
                        </a>
                      </Item>
                    );
                  })
                }
                </Container>
              </React.Fragment>
            }

            {downloadUrlAttachment && (
              <LinkAttacment
                target="_blank"
                href={downloadUrlAttachment}
              >
                Baixar anexo
              </LinkAttacment>
            )}
          </PageContent>
        )}
      </>
    </PageTemplate>
  );
};
