import {
  getRouterByMaterialCorp,
  clickDownload
} from '@digi-tim-19/utils/build';
import { Material, EnumFileKind } from '../autogenerated/client/types';
import { ActionButtonOptions } from '@digi-tim-19/components/build/main/ActionButton/MaterialActionButton';

export function accessMaterial(material: any, history: any, routes: any) {
  const routesMaterial = getRouterByMaterialCorp(material, routes);
  if (routesMaterial.routerAccess) {
    history.push(routesMaterial.routerAccess);
  } else if (routesMaterial.routerDownload) {
    clickDownload(routesMaterial.routerDownload);
  }
  return routesMaterial;
}

export function actionsCard(
  material: Material,
  routes: any,
  history: any,
  showButtonDownload?: boolean
): ActionButtonOptions {
  const routesMaterial = getRouterByMaterialCorp(material, routes);
  const { routerAccess } = routesMaterial;
  const accessBTN = {
    share: true,
    download: showButtonDownload,
    accessButton: getAccessButton(material, history, routes)
  };

  const downloadBTN = {
    share: true,
    download: showButtonDownload
  };

  return routerAccess ? accessBTN : downloadBTN;
}

export function obterImagemPadraoPorMaterial(item: any, isList?: boolean) {
  let thumb = '';

  const imageFile = item?.files.find((file: any) => {
    if (!file.signedUrl) return;
    return (
      file.kind === EnumFileKind.CardThumb ||
      file.kind === EnumFileKind.VideoThumb
    );
  });

  if (imageFile?.signedUrl) return imageFile.signedUrl;

  return thumb;
}

export function actionsButton(
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    share: true,
    pin: false
  };
}

export function actionsButtonFavorites(
  material: Material,
  routes: any,
  history: any,
  listFolders: any,
  addFolder: (
    name: string,
    cb: (error: null | string, result?: any) => any
  ) => any
): ActionButtonOptions {
  return {
    pinFolder: {
      listFolders: listFolders,
      actionAddFolder: addFolder
    },
    folder: {
      listFolders: listFolders
    },
    accessButton: getAccessButton(material, history, routes),
    share: true,
    pin: false
  };
}

export function getAccessButton(material: Material, history: any, routes: any, callback?: (material: Material) => any) {
  const routesMaterial = getRouterByMaterialCorp(material, routes);
  const { routerAccess, routerDownload } = routesMaterial;

  return {
    label: !!routerAccess ? 'ACESSAR' : 'DOWNLOAD',
    action: () => {
      if (routerAccess) {
        history.push(routerAccess);
      } else if (routerDownload) {
        if (callback) callback(material);
        clickDownload(routerDownload);
      }
    }
  };
}
