import React from 'react';
import { useHistory } from 'react-router';
import { StyleLogo } from './styles';
import logo from '../images/Logo.png';

export const Logo = () => {
  const history = useHistory();

  const redirectToHome = () => {
    history.push('/');
  };

  return (
    <>
      <StyleLogo
        src={logo}
        alt="logo"
        onClick={redirectToHome}
        width={116}
        height={71}
      />
    </>
  );
};
