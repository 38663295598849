import { useClient } from '../../autogenerated/client/client';

const appendToFragment = `
  notifications {
    readIds
    unreadIds
  }
  jobTitle {
    name
  }
  roleGroup {
    _id
    name
    channelName
  }
  acceptedTerms {
    term
  }
`;

export function useCurrentUser() {
  const state = useClient('User', {
    fetchOnMount: true,
    appendToFragment
  });

  return {
    ...state,
    userId: (state.result ? state.result._id : '') as string
  };
}
