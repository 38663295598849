import React from 'react';
import styled from 'styled-components';
import {
  TemplateCorp01Visualization,
  TemplateCorp02Visualization,
  TemplateCorp03Visualization,
  TemplateCorp04Visualization,
  TemplateCorp05Visualization,
  TemplateCorp06Visualization,
  TemplateCorp07Visualization,
  TemplateCorp08Visualization,
  TemplateCorp09Visualization,
  TemplateCorp10Visualization
} from '@digi-tim-19/components';
import { TemplatesEmailItem } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';

const Iframe = styled.iframe.attrs({
  className: 'ui-flex PageLoadHTML_Iframe'
})`
  border: none;
  width: 100%;
  min-height: 100vh;
`;

type PropsSwitchTemplate = {
  templateName: string;
  templateProps: TemplatesEmailItem;
  html: string;
};

export const SwitchTemplate = ({
  templateName,
  templateProps,
  html
}: PropsSwitchTemplate) => {
  const iframeRef = React.useRef<HTMLFrameElement | null>(null);

  React.useEffect(() => {
    if (html) {
      iframeRef.current?.contentDocument?.write(html);
      iframeRef.current?.contentDocument?.close();
    }
  }, [html]);

  switch (templateName) {
    case 'templateCorp01':
      return <TemplateCorp01Visualization {...templateProps} />;
    case 'templateCorp02':
      return <TemplateCorp02Visualization {...templateProps} />;
    case 'templateCorp03':
      return <TemplateCorp03Visualization {...templateProps} />;
    case 'templateCorp04':
      return <TemplateCorp04Visualization {...templateProps} />;
    case 'templateCorp05':
      return <TemplateCorp05Visualization {...templateProps} />;
    case 'templateCorp06':
      return <TemplateCorp06Visualization {...templateProps} />;
    case 'templateCorp07':
      return <TemplateCorp07Visualization {...templateProps} />;
    case 'templateCorp08':
      return <TemplateCorp08Visualization {...templateProps} />;
    case 'templateCorp09':
      return <TemplateCorp09Visualization {...templateProps} />;
    case 'templateCorp10':
      return <TemplateCorp10Visualization {...templateProps} />;
    case 'templateCorp11':
      return <TemplateCorp06Visualization {...templateProps} />;
    case 'templateCorp12':
      return <TemplateCorp07Visualization {...templateProps} />;
    case 'templateCorp13':
      return <TemplateCorp08Visualization {...templateProps} />;
    case 'templateCorp14':
      return <TemplateCorp09Visualization {...templateProps} />;
    case 'templateCorp15':
      return <TemplateCorp10Visualization {...templateProps} />;
    default:
      if (html !== '')
        return (
          <Iframe
            ref={(ref: any) => {
              if (ref) {
                iframeRef.current = ref;
              }
            }}
            id={'iframe-html-preview'}
          />
        );

      return <React.Fragment></React.Fragment>;
  }
};
