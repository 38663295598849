import { useClient } from '../../autogenerated/client/client';

export const useNotificationsCategory = () => {
  const getNotificationsCategories = useClient('CategoryFindMany', {
    fetchOnMount: true,
    variables: {
      limit: 123,
      filter: {
        _ids: [
          'menu:para_voce',
          'menu:processos',
          'menu:para_o_cliente',
          'menu:videos',
          'menu:eventos'
        ]
      }
    }
  });

  return getNotificationsCategories?.result?.map((item) => ({
    label: item?.name?.toUpperCase(),
    value: item?._id
  }));
};
