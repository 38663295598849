import { colors } from '@digi-tim-19/theme';
import styled from 'styled-components';

type ContainerTopBarProps = {
  menuIsOpen: boolean;
  breadcrumb: React.ReactNode;
};

export const ProfileAlertContainer = styled.div`
  display: flex;
  width: 50%;
  margin: 0 auto;
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 160px;
  border-bottom: 1px solid ${colors.blue};
  padding: 16px 24px 2px 24px;
`;

export const HeaderContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FirstDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserBellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HamburgerButton = styled.button`
  border: none;
  background-color: #fff;
  transition: transform 0.3s ease;
  cursor: pointer;

  .icon {
    color: ${colors.red};
    font-size: 24px;
  }
`;

export const SecondDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;

  & .position {
    margin-bottom: 15px;
    margin-left: 22px;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding-right: 73px;
  }
`;

export const TotalPoints = styled.span`
  color: ${colors.blue};
  font-family: TimBold;
  font-weight: bold;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.blue};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue};
  }
`;

export const BreadCrumbItem = styled.div`
  margin-right: 8px;
  white-space: nowrap;
`;

export const ContainerTopBar = styled.div<ContainerTopBarProps>`
  height: ${({ menuIsOpen, breadcrumb }) =>
    breadcrumb && !menuIsOpen ? '130px' : menuIsOpen ? '65px' : '65px'};
  padding-top: 5px;
  padding-left: ${({ breadcrumb, menuIsOpen }) =>
    !breadcrumb || (breadcrumb && menuIsOpen) ? '0' : '20px'};
  width: 100%;
  box-shadow: 2px 2px 16px -4px rgba(34, 34, 34, 0.3);
  .ektqvm {
    margin-bottom: 0;
  }
`;

export const SearchWrapperContainer = styled.div<{
  breadcrumb: boolean;
  menuIsOpen: boolean;
}>`
  padding-top: ${({ breadcrumb, menuIsOpen }) =>
    menuIsOpen ? '5px' : breadcrumb ? '14px' : '5px'};
`;

export const SearchWrapper = styled.div`
  padding: 0px 35px;
  max-width: 500px;
  display: flex;
  margin: 0 auto;
  .ant-input-search {
    input {
      width: 100%;
      background: #efefef !important;
      height: 40px !important;
      border-radius: 80px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 0px;
    max-width: 450px;
    margin-left: 20px;
    width: 50%;
  }
`;

export const SVGContainer = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding-top: 6px;
`;

export const SVGIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const SVGText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.blue};
`;
