import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { TextEditor } from '@digi-tim-19/components';
import styled from 'styled-components';
import { TFilesLink } from './DocsEditor';
import { ImageControl } from './ImageControl';
import CollapseHTML from './CollapseHTML';
import { TableHTML } from './Table/TableHTML';
import { LinkControl } from './LinkControl';

type DataPayload =
  | { kind: 'html'; html: string }
  | { kind: 'link'; title: string; children: EditorItem[] }
  | { kind: 'table'; cols: any[]; rows: any[] };

export type EditorItem = {
  _id: number;
  data: DataPayload;
};

type TDocsEditorFormProps = {
  onChange: (selected: any[]) => any;
  files?: TFilesLink;
  initialValues?: any[];
  previewMode?: boolean;
  size?: 'default' | 'small';
};

export const DocsEditorForm = (props: TDocsEditorFormProps) => {
  const [data, setData] = useState<EditorItem[]>(props.initialValues ?? []);
  useEffect(() => props.onChange(data), [data]);

  const addDefaultContent = () => {
    setData((e) => [
      ...e,
      { _id: e.length, data: { kind: 'html', html: '<p>Edite aqui</p>\n' } }
    ]);
  };

  const addDefaultTable = () => {
    setData((e) => [
      ...e,
      { _id: e.length, data: { kind: 'table', cols: [], rows: [] } }
    ]);
  };

  const addDefaultAcordeon = () => {
    setData((e) => [
      ...e,
      { _id: e.length, data: { kind: 'link', title: 'Title', children: [] } }
    ]);
  };

  const handleDelete = (_id: number) => {
    setData((state) => state.filter((e) => e._id !== _id));
  };

  const handleMoveTo = (oldPosition: number, newPosition: number) => {
    setData((state) => {
      if (newPosition < 0 || newPosition >= state.length) return state;
      const itemToMove = state[oldPosition];
      const updatedState = state.filter((_, index) => index !== oldPosition);
      updatedState.splice(newPosition, 0, itemToMove);
      return updatedState;
    });
  };

  const handleChange = (_id: number, data: EditorItem['data']) => {
    return setData((e) =>
      e.map((item) => (item._id === _id ? { ...item, data } : item))
    );
  };

  const positionRef = useRef<number>();

  const startDrag = (index: number) => {
    positionRef.current = index;
  };

  const endDrag = (index: number) => {
    if (!positionRef.current || positionRef.current === index) return;
    const newPosition = positionRef.current;
    positionRef.current = undefined;
    handleMoveTo(newPosition, index);
  };

  return (
    <>
      <EditorFormWrapper>
        {Array.isArray(data) &&
          data.map((item, index) => (
            <div
              key={item._id}
              draggable={!props.previewMode}
              onDragStart={() => startDrag(index)}
            >
              {!props.previewMode && (
                <DragArea onDragFinish={() => endDrag(index)} />
              )}
              <ItemContainer
                previewMode={props.previewMode}
                renderItem={(previewMode) => {
                  switch (item.data.kind) {
                    case 'html':
                      return (
                        <TextEditor
                          value={item.data.html!}
                          previewMode={previewMode}
                          onChange={(e) =>
                            handleChange(item._id, { kind: 'html', html: e })
                          }
                          imageComponent={(config: any) => (
                            <ImageControl
                              config={config}
                              files={props.files ?? []}
                            />
                          )}
                          linkComponent={(config: any) => (
                            <LinkControl
                              config={config}
                              files={props.files ?? []}
                            />
                          )}
                        />
                      );
                    case 'table':
                      return (
                        <TableHTML
                          item={item}
                          onChange={(e: any) => handleChange(item._id, e)}
                          previewMode={previewMode}
                        />
                      );
                    case 'link':
                      return (
                        <CollapseHTML
                          item={item}
                          files={props.files}
                          onChange={(data) => handleChange(item._id, data)}
                          previewMode={previewMode}
                        />
                      );
                  }
                }}
                onDelete={() => handleDelete(item._id)}
                onMoveUp={() => handleMoveTo(index, index - 1)}
                onMoveDown={() => handleMoveTo(index, index + 1)}
              />
              {index === data.length - 1 && !props.previewMode && (
                <DragArea onDragFinish={() => endDrag(index)} />
              )}
            </div>
          ))}
      </EditorFormWrapper>
      {!props.previewMode && (
        <ButtonsWrapper>
          <Button icon="plus" size={props.size} onClick={addDefaultContent}>
            Conteúdo
          </Button>
          <Button icon="plus" size={props.size} onClick={addDefaultTable}>
            Tabela
          </Button>
          <Button icon="plus" size={props.size} onClick={addDefaultAcordeon}>
            Link recolhível
          </Button>
        </ButtonsWrapper>
      )}
    </>
  );
};

interface ItemContainerProps {
  previewMode?: boolean;
  renderItem: (editMode: boolean) => React.ReactNode;
  onDelete: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
}

const ItemContainer = (props: ItemContainerProps) => {
  const [previewMode, setPreviewMode] = useState<boolean>(
    props.previewMode ?? false
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}
    >
      {props.renderItem(previewMode)}
      {!props.previewMode && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Button
              icon={previewMode ? 'edit' : 'eye'}
              onClick={() => setPreviewMode((e) => !e)}
            />
            <Button icon="delete" onClick={props.onDelete} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Button icon="up" onClick={props.onMoveUp} />
            <Button icon="down" onClick={props.onMoveDown} />
          </div>
        </div>
      )}
    </div>
  );
};

interface DragAreaProps {
  onDragFinish: () => void;
}
const DragArea = (props: DragAreaProps) => {
  const [dragHover, setDragHover] = useState(false);
  return (
    <div
      style={{
        width: '100%',
        height: '20px',
        border: dragHover ? '1px dashed blue' : 'none'
      }}
      onDragEnter={() => setDragHover(true)}
      onDragLeave={() => setDragHover(false)}
      onDragOver={() => {
        props.onDragFinish();
        setDragHover(false);
      }}
      onDrop={() => {
        props.onDragFinish();
        setDragHover(false);
      }}
    />
  );
};

const EditorFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
