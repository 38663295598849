import { colors } from '@digi-tim-19/theme';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid ${colors.blue};
  min-width: 280px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 10px;
    padding: 10px;
  }
`;

export const HeaderContainerItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  & .style {
    display: flex;
    align-items: center;
    min-width: 200px;
  }
`;

export const ContainerTopBar = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 24px -4px rgba(78, 78, 78, 0.2);
  .ektqvm {
    border-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    height: auto;
  }
  .ektqvm {
    border-bottom: 0px;
  }
`;

export const SearchWrapper = styled.div`
  padding: 20px;
  .ant-input-search {
    input {
      width: 100%;
      background: #efefef !important;
      height: 40px !important;
      border-radius: 80px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 0px;
    max-width: 450px;
    margin-left: 20px;
    width: 50%;
  }
`;

export const DivProfileAlert = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
`;

export const ContentTopBar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  gap: 8px;

  & > span {
    margin-left: 20px;
    .ektqvm {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 10px;
  }
`;

export const SVGContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const SVGIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const SVGText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.blue};
  font-family: TimBold;
`;

export const BreadcrumbContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;
