import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router';
import { DatePicker, Pagination } from 'antd';
import {
  Breadcrumb,
  TitleDescription,
  CardInterno,
  LoadingIndicator,
  SelectFilter
} from '@digi-tim-19/components';

import { routes } from 'config/routes';
import { getAccessButton } from 'utils/material';
import { useClient } from 'autogenerated/client/client';
import { PageTemplate } from 'components/Layout/PageTemplate';
import { CleanButton } from 'components/PageFilter';
import { useCategoryCore } from 'hooks/categories';
import {
  DateRange,
  EnumMaterialKind,
  EnumMaterialStatus,
  SortFindManyMaterialInput
} from 'autogenerated/client/types';

import * as S from './styles';
import { additionalCategoriesOptions } from './FormInformativos/FormInformativos';

const { RangePicker } = DatePicker;

export const Informativos = () => {
  const history = useHistory();
  const categoriesCoreOptions = useCategoryCore();
  const { params }: any = useRouteMatch();
  const residentialMenu = 'menu:informativos';
  const categoryMenu = !params?.menu ? residentialMenu : `menu:${params.menu}`;
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);
  const [rangePickerShow, setRangePickerShow] = useState();
  const [filterUpdatedAt, setFilterUpdatedAt] = useState<DateRange>();
  const [filterCategory, setFilterCategory] = useState(undefined);
  const [sort, setSort] = React.useState<SortFindManyMaterialInput>(
    SortFindManyMaterialInput.CreatedatDesc
  );

  const getMaterials = useClient('MaterialPagination');

  const selectsOrders = React.useMemo(() => {
    const options = [
      {
        defaultValue: sort || SortFindManyMaterialInput.CreatedatDesc,
        value: sort || SortFindManyMaterialInput.CreatedatDesc,
        action: (value: SortFindManyMaterialInput) => {
          setSort(value);
        },
        options: [
          {
            name: 'Mais antigo',
            value: SortFindManyMaterialInput.CreatedatAsc
          },
          {
            name: 'Mais recente',
            value: SortFindManyMaterialInput.CreatedatDesc
          }
        ]
      },
      {
        placeholder: "Categoria: todas",
        defaultValue: filterCategory || "Categoria: todas",
        value: filterCategory || "Categoria: todas",
        action: (value: any) => {
          setFilterCategory(value);
        },
        options: categoriesCoreOptions
          ? [...categoriesCoreOptions, ...additionalCategoriesOptions].map(
              (option: any) => {
                return {
                  value: option.value,
                  name: option?.name || option?.label
                };
              }
            )
          : []
      }
    ];
    return [ ...options ];
  }, [categoriesCoreOptions, sort, filterCategory]);

  const resetFilters = () => {
    setFilterUpdatedAt(undefined);
    setFilterCategory(undefined);
    setRangePickerShow(undefined);
    setSort(SortFindManyMaterialInput.CreatedatDesc);
  };

  const getFilterCategories = (filterCategory: any) => {
    let filter: any = {
      inCategories: [
        'menu:para_voce:comunicacao:informativos',
        'menu:informativos'
      ],
      inAdditionalCategories: undefined
    };

    if (filterCategory) {
      if (filterCategory.includes('menu')) {
        filter.inCategories= [filterCategory];
        filter.inAdditionalCategories = undefined;
      } else {
        filter.inCategories = undefined;
        filter.inAdditionalCategories = [filterCategory];
      }
    }

    return filter;
  };

  useEffect(() => {
    const filterCategories = getFilterCategories(filterCategory);

    getMaterials
      .fetch({
        appendToFragment,
        variables: {
          perPage: 15,
          page,
          filter: {
            kind: EnumMaterialKind.Communication,
            updatedAtRange: filterUpdatedAt,
            ...filterCategories,
            OR: [
              { status: EnumMaterialStatus.Approved },
              { status: EnumMaterialStatus.Published },
              { status: EnumMaterialStatus.PublishNow }
            ]
          },
          sort: sort
        }
      })
      .then((ctx) => {
        const data =
          ctx?.result?.items?.map((material) => {
            let categories: any =
              material?.additionalCategories?.map((value: any) => {
                const option = additionalCategoriesOptions.find(
                  (el) => el.value === value
                );
                return {
                  _id: option?.value,
                  name: option?.label
                };
              }) || [];

            if (categories.length === 0) {
              categories = material?.categories || [];
            }

            return { ...material, categories };
          }) || [];
        setItems([...data]);
      });
  }, [filterUpdatedAt, filterCategory, sort, page]); //eslint-disable-line

  const isLoading = getMaterials.loading;
  const total = getMaterials.result?.count;

  return (
    <PageTemplate
      breadcrumb={
        <Breadcrumb
          items={categoryMenu === residentialMenu ? [
            { title: 'Home', link: routes.home.mount() },
            { title: 'Informativos' }
          ] : [
            { title: 'Home', link: routes.home.mount() },
            { title: 'Para você' },
            { title: 'Comunicação' },
            { title: 'Informativos' }
          ]}
        />
      }
    >
      <>
        <TitleDescription
          iconType={'info-circle'}
          title={'Informativos'}
          description={'Aqui você encontra todos os informativos'}
        />

        <S.PageContent>
          <S.FilterWrapper>
            <S.OrderWrapper>
              <SelectFilter
                selects={selectsOrders}
              />

              <RangePicker
                getCalendarContainer={(triggerNode: any) =>
                  triggerNode.parentNode
                }
                format="DD/MM/YYYY"
                placeholder={['Período', '']}
                onChange={(e: any) => {
                  const dateISOArr = e.map((item: any) =>
                    moment(item).toISOString()
                  );
                  setRangePickerShow(e);
                  setFilterUpdatedAt({
                    start: dateISOArr[0],
                    end: dateISOArr[1]
                  });
                }}
                value={rangePickerShow}
              />

              <CleanButton onClick={() => resetFilters()}>
                Limpar filtros
              </CleanButton>
            </S.OrderWrapper>
          </S.FilterWrapper>

          {isLoading && <LoadingIndicator />}

          {!isLoading && (
            <>
              <CardInterno
                defaultImage={'/defaultImages/default-card-thumb.png'}
                items={items}
                actions={(material: any) => {
                  return {
                    pin: true,
                    share: true,
                    accessButton: getAccessButton(material, history, routes)
                  };
                }}
              />
              <Pagination
                pageSize={15}
                current={page}
                total={total || 0}
                onChange={(page) => setPage(page)}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </>
          )}
        </S.PageContent>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  count
  pageInfo {
    perPage
    currentPage
    itemCount
  }
  items {
    description
    title
    html
    files {
      _id
      signedUrl
      downloadUrl
      extension
      kind
    }
    communicationItemsConfig {
      kind
    }
    additionalCategories
    categories {
      _id
      name
    }
    kind
    createdAt
    _id
  }
`;
