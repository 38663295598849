import React from 'react';
import { useHistory } from 'react-router-dom';
import { Bellicon } from './styles';
import bellIcon from '../images/bell.svg';
import { Notifications } from 'autogenerated/client/types';

interface BellIconProps {
  notifications?: Notifications | null;
}

export const BellIcon = ({ notifications }: BellIconProps) => {
  const history = useHistory();

  const hasUnreadNotifications = () => {
    return notifications?.unreadIds && notifications.unreadIds.length > 0;
  };

  const redirectToNotifications = () => {
    history.push('/notificacoes');
  };

  return (
    <Bellicon onClick={redirectToNotifications}>
      <img src={bellIcon} alt="bell icon" />

      {hasUnreadNotifications() && <div className="red-dot" />}
    </Bellicon>
  );
};
