import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import { ItemMenu } from './ItemMenu';
import { SubMenu } from './SubMenu';
import { GroupItemMenuProp, TypeMenuItem } from '../PageTemplate';
import { User } from 'autogenerated/client/types';
import { UserSession } from '@digi-tim-19/utils';
import { useCurrentUser } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import { colors } from '@digi-tim-19/theme';

export type MainMenuConfig = {
  menuIsOpen: boolean;
  itemsMenuSettings: GroupItemMenuProp;
  onMenuExpand: () => void;
  redirecionarLink: Function;
  onSubMenuExpand?: Function;
  subMenuIsOpen?: boolean;
  dimensions?: any;
};

const NavWrapper = styled.nav<{ menuOpen?: boolean; dimensions?: boolean }>`
  background-color: ${colors.blue};
  height: 100%;
  width: ${({ menuOpen }) => (!menuOpen ? '296px' : '72px')};
  padding: ${({ dimensions }) => (dimensions ? '10px 16px' : '43px 16px 0px 16px')};
  position: relative;
  transition: all 0.25s ease;

  & span {
    visibility: ${({ menuOpen }) => (!menuOpen ? 'visible' : 'hidden')};
    white-space: nowrap;
  }

  @media screen and (max-width: 1024px) {
    width: ${({ menuOpen }) => (menuOpen ? '296px' : '72px')};

    & span {
      visibility: ${({ menuOpen }) => (menuOpen ? 'visible' : 'hidden')};
      white-space: nowrap;
    }
  }

  @media screen and (max-height: 890px) {
    ul {
      margin-top: 4px;

      li {
        margin-bottom: 6px;
      }
    }
  }
  @media screen and (max-height: 800px) {
    ul {
      margin-top: 4px;

      li {
        margin-bottom: 2px;
      }
    }
  }
  @media screen and (max-height: 720px) {
    ul {
      margin-top: -1px;

      li {
        margin-bottom: -1px;
      }
    }
  }
`;

const ListMenu = styled.ul`
  padding-left: 0px;
  height: 100%;
  list-style: none;
  margin-top: 14px;

  .btnToggleMenu {
    padding: 6px;
    position: absolute;
    top: 12px;
    right: 16px;

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }
`;

const ItemList = styled.li`
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  color: ${colors.red};
  font-family: TimBold;
  margin-bottom: 12px;
  padding: 6px 0;

  & span {
    font-size: 14px;
  }

  :first-child:hover {
    background-color: ${colors.red};
    border-radius: 12px;
  }
`;

export const MenuPrincipal = ({
  menuIsOpen,
  itemsMenuSettings,
  onMenuExpand,
  redirecionarLink,
  onSubMenuExpand,
  subMenuIsOpen,
  dimensions
}: MainMenuConfig) => {
  const [selectedItem, setSelectedItem] = useState<TypeMenuItem | null>();
  const user = useCurrentUser().result as User;
  const history = useHistory();

  function handleLogout() {
    UserSession.clear();
    const redirectUrl = user?.matricula ? '/open/login' : '/';
    document.location.href = redirectUrl;
  }

  function handleItemMenuClick(itemMenu: TypeMenuItem) {
    if (itemMenu.children && itemMenu.children.length > 0) {
      setSelectedItem(prev => {
        if(prev?._id === itemMenu?._id && subMenuIsOpen) {
          onSubMenuExpand && onSubMenuExpand(false);
          return null;
        }
        onSubMenuExpand && onSubMenuExpand(true);
        return itemMenu;
      });
      return;
    }

    if (itemMenu?.routeName !== '/') {
      redirecionarLink(itemMenu);
      onSubMenuExpand && onSubMenuExpand(false);
      return;
    }
  }

  return (
    <>
      <NavWrapper
        menuOpen={menuIsOpen}
        dimensions={dimensions > 1024}
      >
        <ListMenu>
          {itemsMenuSettings.items && (
            <ItemList onClick={() => history.push('/')}>
              <Icon
                type="home"
                style={{ padding: '0 8px', fontSize: '23px', color: 'white' }}
              />
              <span style={{ color: 'white' }}>Home</span>
            </ItemList>
          )}

          {itemsMenuSettings.items && (
            <ItemList
              className="btnToggleMenu"
              onClick={() => onMenuExpand && onMenuExpand()}
            >
              <Icon
                style={{ fontSize: '19px', color: 'white' }}
                type={
                  (dimensions <= 1280 && !menuIsOpen) ||
                  (menuIsOpen && dimensions >= 1280)
                    ? 'double-right'
                    : 'double-left'
                }
              />
            </ItemList>
          )}

          {itemsMenuSettings.items &&
            itemsMenuSettings.items.map(
              (itemMenu: TypeMenuItem, index: number) => (
                <ItemMenu
                  key={index}
                  item={itemMenu}
                  handleItemMenuClick={() => handleItemMenuClick(itemMenu)}
                />
              )
            )}

          {itemsMenuSettings.items && (
            <ItemList onClick={() => handleLogout()}>
              <Icon
                type="logout"
                style={{ padding: '0 8px', fontSize: '23px' }}
              />
              <span>Logout</span>
            </ItemList>
          )}
        </ListMenu>
      </NavWrapper>
      {selectedItem && (
        <SubMenu
          selectedItem={selectedItem}
          redirecionarLink={redirecionarLink}
          menuIsOpen={menuIsOpen}
          subMenuIsOpen={subMenuIsOpen}
          setSubMenuIsOpen={() => handleItemMenuClick(selectedItem)}
        />
      )}
    </>
  );
};
