import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'antd';

import { TypeMenuItem } from '../../PageTemplate';
import { SubMenuConfig } from './SubMenuMobile';
import { colors } from '@digi-tim-19/theme';

function ucfirst(text: string, nivel: number) {
  if (nivel === 3) {
    return text.toUpperCase();
  }

  if (text && text !== '' && (nivel === 4 || nivel === 5)) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
  }
  return text;
}

const stylesMap: { [key: number]: string } = {
  1: `
  li {
      font-size: 14px;
      font-weight: 700;
      color: white;
    }
    
  `,
  2: `
  .item {
    padding: 16px 40px 8px 28px;
    font-family: TimBold;
  }
  `,
  3: `
  font-size: 14px;
  color: #535353;
  .item {
    border: 0;
    padding: 8px 40px 8px 8px; 
  } 
  `,
  4: `
    font-size: 13px;
    font-weight: 600;
    padding-left: 8px;
    .item {
      padding: 8px;
      margin: 0px 24px 0px 8px;
      font-family: TimRegular;
    }
  `,
  5: `
    li {
      list-style-type: disc;
      margin-left: 20px;
      font-family: TimLight;
    }
    padding-left: 20px;
    font-weight: 400;
  `
};

const MenuSubMenuWrap = styled.div<{ nivel: number; submenuOpen: boolean }>`
  ${({ nivel }) => stylesMap[nivel]};

  border: ${({ nivel, submenuOpen }) =>
    nivel === 2 && submenuOpen && '1px solid #D3D3D3'};

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    border-bottom: ${({ nivel }) => nivel === 2 && '1px solid #D3D3D3'};
  }
`;

export const SubMenuMobileItem = ({
  subMenuitem,
  redirecionarLink
}: SubMenuConfig) => {
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

  function toggleSubmenu(item: TypeMenuItem) {
    if (item.children && item.children.length > 0) {
      setSubmenuOpen((prev) => !prev);
      return;
    }
  }

  const getStyleByNivel = (nivel: number) => {
    const styles1 = {
      backgroundColor: colors.blue,
      color: 'white'
    };
    const styles2 = {
      backgroundColor: '#61C5C433'
    };

    return nivel === 3 ? styles1 : nivel === 4 ? styles2 : {};
  };

  return (
    <MenuSubMenuWrap
      nivel={subMenuitem.nivel}
      submenuOpen={submenuOpen}
      key={subMenuitem._id}
    >
      <ul
        className="item"
        style={submenuOpen ? getStyleByNivel(subMenuitem.nivel) : {}}
        onClick={() => toggleSubmenu(subMenuitem)}
      >
        <li onClick={() => redirecionarLink && redirecionarLink(subMenuitem)}>
          {ucfirst(subMenuitem.text, subMenuitem.nivel)}
        </li>
        {subMenuitem.children && subMenuitem.children.length > 0 && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              toggleSubmenu(subMenuitem);
            }}
          >
            <Icon
              type={submenuOpen ? 'up' : 'down'}
              style={{ fontSize: '16px' }}
            />
          </span>
        )}
      </ul>
      {submenuOpen &&
        subMenuitem.children &&
        subMenuitem.children.length > 0 &&
        subMenuitem.children.map((child: TypeMenuItem) => (
          <SubMenuMobileItem
            key={child._id}
            subMenuitem={child}
            redirecionarLink={redirecionarLink}
          />
        ))}
    </MenuSubMenuWrap>
  );
};
