import {
  makeField,
  TemplateCorp01,
  TemplateCorp02,
  TemplateCorp03,
  TemplateCorp04,
  TemplateCorp05,
  TemplateCorp06,
  TemplateCorp07,
  TemplateCorp08,
  TemplateCorp09,
  TemplateCorp10,
  useCurrentUser,
  useField
} from '@digi-tim-19/components';
import React from 'react';

import { siteImages } from '../../../assets/images';

type Section = "communication" | "remuneration";

const headerLogoRemuneration = "/defaultImages/template-header-remuneration.png";

export const TemplateFields = ({ section = "communication" }: { section?: Section }) => {
  const user = useCurrentUser()?.result;
  const HIDE_BUTTON = useField('templateProps.HIDE_BUTTON').input.value;
  const BUTTON_TITLE = useField('templateProps.BUTTON_TITLE').input.value || '';
  const BUTTON_LINK = useField('templateProps.BUTTON_LINK').input.value || '';
  const templateName = useField('templateName').input.value;

  const [hideButton, setHideButton] = React.useState(!!HIDE_BUTTON);

  let IMAGE_SRC_1: any = '';
  switch (templateName) {
    case 'templateCorp01':
      IMAGE_SRC_1 = siteImages.bgTemplateEmail1000x420;
      break;

    case 'templateCorp02':
      IMAGE_SRC_1 = siteImages.bgTemplateEmail640x250;
      break;

    case 'templateCorp03':
      IMAGE_SRC_1 = siteImages.bgTemplateEmail640x300;
      break;

    case 'templateCorp05':
      IMAGE_SRC_1 = siteImages.bgTemplateEmail640x250White;
      break;

    case 'templateCorp06':
    case 'templateCorp07':
    case 'templateCorp08':
    case 'templateCorp09':
    case 'templateCorp10':
    case 'templateCorp11':
    case 'templateCorp12':
    case 'templateCorp13':
    case 'templateCorp14':
    case 'templateCorp15':
      IMAGE_SRC_1 = siteImages.bgTemplateEmail600x900;
      break;
  }

  const templateProps = React.useMemo(() => {
    return {
      headerLogo: '/defaultImages/template-header-corp-residencial.png',
      footerLogo: '/defaultImages/template-footer-corp-residencial.png',
      defaultProps: {},
      defaultImageBody: IMAGE_SRC_1,
      hideButton: hideButton
    };
  }, [templateName, hideButton]);

  const optionsFiltered = (options: any, section: Section, user?: any) => {
    if(section === "communication") {
      if(!user || user?.roleGroup?._id?.includes('corporate:')) {
        return options.filter(
          (option: any) => Number(option.label) <= 5
        );
      }
      return options.filter(
        (option: any) => Number(option.label) > 5 && Number(option.label) <= 10
      );
    }
    return options.filter(
      (option: any) => Number(option.label) > 10
    );
  }

  const child = React.useMemo(() => {
    return makeField({
      key: 'templateFields',
      field: {
        list: [
          {
            custom: (
              <h4 style={{ width: '100%', margin: '0' }}>
                Selecione o template de email:
              </h4>
            )
          },
          {
            ...templateNameField,
            options: optionsFiltered(templateNameField.options, section, user)
          },
          {
            name: 'template',
            label: ' ',
            custom: {
              render: function () {
                switch (templateName) {
                  case 'templateCorp01':
                    return <TemplateCorp01 {...templateProps} />;
                  case 'templateCorp02':
                    return <TemplateCorp02 {...templateProps} />;
                  case 'templateCorp03':
                    return <TemplateCorp03 {...templateProps} />;
                  case 'templateCorp04':
                    return <TemplateCorp04 {...templateProps} />;
                  case 'templateCorp05':
                    return <TemplateCorp05 {...templateProps} />;
                  case 'templateCorp06':
                    return <TemplateCorp06 {...templateProps} />;
                  case 'templateCorp07':
                    return <TemplateCorp07 {...templateProps} />;
                  case 'templateCorp08':
                    return <TemplateCorp08 {...templateProps} />;
                  case 'templateCorp09':
                    return <TemplateCorp09 {...templateProps} />;
                  case 'templateCorp10':
                    return <TemplateCorp10 {...templateProps} />;
                  case 'templateCorp11':
                    return <TemplateCorp06 {...templateProps} headerLogo={headerLogoRemuneration} />;
                  case 'templateCorp12':
                    return <TemplateCorp07 {...templateProps} headerLogo={headerLogoRemuneration} />;
                  case 'templateCorp13':
                    return <TemplateCorp08 {...templateProps} headerLogo={headerLogoRemuneration} />;
                  case 'templateCorp14':
                    return <TemplateCorp09 {...templateProps} headerLogo={headerLogoRemuneration} />;
                  case 'templateCorp15':
                    return <TemplateCorp10 {...templateProps} headerLogo={headerLogoRemuneration} />;
                }

                return null;
              }
            }
          },

          templateName && !['templateCorp05', 'templateCorp06', 'templateCorp11'].includes(templateName) && {
              inline: true,
              list: [
                [
                  'templateCorp07',
                  'templateCorp08',
                  'templateCorp09',
                  'templateCorp10',
                  'templateCorp12',
                  'templateCorp13',
                  'templateCorp14',
                  'templateCorp15',
                ].includes(templateName) && {
                  initialValue: !!HIDE_BUTTON,
                  name: 'templateProps.HIDE_BUTTON',
                  label: 'Ocultar botão?',
                  options: [
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false }
                  ],
                  afterChange: (v: boolean) => setHideButton(v)
                },
                {
                  initialValue: !!HIDE_BUTTON,
                  name: 'templateProps.BUTTON_TITLE',
                  label: 'Título do botão (até 20 caracteres)',
                  defaultValue: BUTTON_TITLE || 'Clique aqui',
                  disabled: hideButton,
                  extraProps: {
                    maxlength: 20
                  }
                },

                {
                  name: 'templateProps.BUTTON_LINK',
                  label: 'Link do botão',
                  defaultValue: BUTTON_LINK || 'https://',
                  disabled: hideButton
                }
              ]
            },

          templateName &&
            ['templateCorp05', 'templateCorp06', 'templateCorp11'].includes(templateName) && {
              inline: true,
              list: [
                {
                  initialValue: HIDE_BUTTON,
                  name: 'templateProps.HIDE_BUTTON',
                  label: 'Possui link?', //Lógica invertida do HIDE_BUTTON para melhor entendimento do usuário
                  required: true,
                  options: [
                    { label: 'Sim', value: false },
                    { label: 'Não', value: true }
                  ],
                  afterChange: (v: boolean) => setHideButton(v)
                },
                {
                  name: 'templateProps.BUTTON_LINK',
                  label: 'Link da imagem',
                  defaultValue: BUTTON_LINK || 'https://',
                  disabled: hideButton
                }
              ]
            }
        ]
      }
    });
  }, [templateName, templateProps, hideButton]);

  return <>{child}</>;
};

const radioWrapperStyleProps = {
  width: '20%'
};

const imageStyleProps = {
  textAlign: 'center'
};

const templateNameField = {
  name: 'templateName',
  label: ' ',
  extraProps: {
    radioGroupStyleProps: {
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'wrap'
    }
  },
  options: [
    {
      label: '1',
      value: 'templateCorp01',
      extraProps: {
        image: '/defaultImages/template-email-01.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '2',
      value: 'templateCorp02',
      extraProps: {
        image: '/defaultImages/template-email-02.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '3',
      value: 'templateCorp03',
      extraProps: {
        image: '/defaultImages/template-email-03.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '4',
      value: 'templateCorp04',
      extraProps: {
        image: '/defaultImages/template-email-04.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '5',
      value: 'templateCorp05',
      extraProps: {
        image: '/defaultImages/template-email-05.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '6',
      value: 'templateCorp06',
      extraProps: {
        image: '/defaultImages/template-email-06.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '7',
      value: 'templateCorp07',
      extraProps: {
        image: '/defaultImages/template-email-07.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '8',
      value: 'templateCorp08',
      extraProps: {
        image: '/defaultImages/template-email-08.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '9',
      value: 'templateCorp09',
      extraProps: {
        image: '/defaultImages/template-email-09.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '10',
      value: 'templateCorp10',
      extraProps: {
        image: '/defaultImages/template-email-10.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '11',
      value: 'templateCorp11',
      extraProps: {
        image: '/defaultImages/template-email-11.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '12',
      value: 'templateCorp12',
      extraProps: {
        image: '/defaultImages/template-email-12.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '13',
      value: 'templateCorp13',
      extraProps: {
        image: '/defaultImages/template-email-13.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '14',
      value: 'templateCorp14',
      extraProps: {
        image: '/defaultImages/template-email-14.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    },
    {
      label: '15',
      value: 'templateCorp15',
      extraProps: {
        image: '/defaultImages/template-email-15.png',
        widthImage: '150px',
        radioWrapperStyleProps,
        imageStyleProps
      }
    }
  ],
  type: 'radio',
  required: true
};
