import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';

import { TypeMenuItem } from '../../PageTemplate';

import { SubMenuMobileItem } from './SubMenuMobileItem';
import { colors } from '@digi-tim-19/theme';

export type SubMenuConfig = {
  subMenuitem: TypeMenuItem;
  handleItemMenuClick?: Function;
  redirecionarLink?: Function;
};

const ItemMenu = styled.li<{ isActive: string | null }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: center;
  color: ${colors.blue};
  width: 100%;
  font-weight: 700;
  margin-bottom: 16px;
  cursor: pointer;
  font-family: TimBold;

  & p {
    margin: 0;
  }

  .itemMenu {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;

    & > div {
      display: flex;
      align-items: center;
      flex: 1;

      & span {
        font-size: 14px;
      }
    }

    ${({ isActive }) =>
      isActive &&
      `
    background-color: #e90029;
    color: #ffffff;
    border-radius: 12px;
  `}
  }
`;

export const SubMenuMobile = ({
  subMenuitem,
  handleItemMenuClick,
  redirecionarLink
}: SubMenuConfig) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  function toggleSubmenu(itemId: string) {
    setActiveItem(activeItem === itemId ? null : itemId);
  }

  return (
    <ItemMenu isActive={activeItem}>
      {subMenuitem && (
        <div className="itemMenu">
          <div
            onClick={() => {
              handleItemMenuClick && handleItemMenuClick();
              toggleSubmenu(subMenuitem._id);
            }}
          >
            <span>
              <Icon
                style={{ fontSize: '24px', paddingRight: '8px' }}
                type={subMenuitem.typeIcon}
              />
            </span>
            <li>{subMenuitem.text}</li>
          </div>
          {subMenuitem.children && subMenuitem.children.length > 0 && (
            <Icon
              onClick={() => toggleSubmenu(subMenuitem._id)}
              type={activeItem === subMenuitem._id ? 'up' : 'down'}
              style={{ padding: '8px 8px 8px 8px', fontSize: '18px' }}
            />
          )}
        </div>
      )}
      {activeItem === subMenuitem._id &&
        subMenuitem.children &&
        subMenuitem.children.length > 0 &&
        subMenuitem.children.map((subItem: TypeMenuItem) => (
          <SubMenuMobileItem
            key={subItem._id}
            subMenuitem={subItem}
            redirecionarLink={redirecionarLink}
          />
        ))}
    </ItemMenu>
  );
};
