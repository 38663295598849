import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ModalTerm } from 'pages/Home/ModalTerm';
import { Banner } from 'pages/Home/Banner';
import { CardsHome } from 'pages/Home/CardsHome';
import { useClient } from 'autogenerated/client/client';
import { useCurrentUser } from 'hooks/user/useCurrentUser';
import { UserEditProfile } from 'components/UserEditProfile/UserEditProfile';
import { User } from 'autogenerated/client/types';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { MainSocialFeed } from 'components/Layout/MainSocialFeed';
import { useHistory } from 'react-router';

const ContentHome = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SocialFeedContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Home = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(true);
  const [sendLogPCSInfo, setSendLogPCSInfo] = useState(false);
  const saveLoginAnalyticsPCS = useClient('UserSaveLoginAnalyticsPCS');
  
  const handleRedirectToUrl = () => {
    const returnTo = sessionStorage.getItem('returnTo') || '';
    if(returnTo !== "") {
      sessionStorage.removeItem('returnTo');
      history.push(window.location.pathname === "/portal" ? `/portal${returnTo}` : returnTo);
    }
  }

  useEffect(() => {
    if (user.data) {
      const hasSecondaryEmail = !!user.data.emailSecondary;
      const isTimBrasil =
        !!user.data.emailSecondary?.includes('@timbrasil.com.br');
      const isSameCorporateEmail = user.data.email === user.data.emailSecondary;
      const showModal =
        !hasSecondaryEmail || isTimBrasil || isSameCorporateEmail;
      const hasAcceptedRules = Boolean(user?.result?.acceptedTerms?.find((item) => item?.term === 'FirstAccessRules'));
      setShowTermsModal(!hasAcceptedRules);
      setShowUpdateModal(showModal);
      if(!showModal && hasAcceptedRules) handleRedirectToUrl();
    }
  }, [user, showTermsModal, showUpdateModal]);

  useEffect(() => {
    if (!sendLogPCSInfo) {
      saveLogLoginPCS();
    }
  }, [sendLogPCSInfo]);

  async function saveLogLoginPCS() {
    setSendLogPCSInfo(true);

    await saveLoginAnalyticsPCS.fetch().then(() => {
      //console.log('save login pcs');
    });
  }

  return (
    <>
      <PageTemplate>
        <>
          {
            Boolean(showTermsModal && !user?.loading)
            &&
            <ModalTerm
              onOk={() => {
                setShowTermsModal(false);
              }}
            />
          }
          {
            Boolean(
              user.data &&
              !showTermsModal &&
              showUpdateModal
            ) && (
            <UserEditProfile
              modalVisible={showUpdateModal}
              user={user.data as User}
              setImgUser={() => {}}
              setVisible={() => {}}
            />
          )}
          <Banner />
          <ContentHome>
            <CardsHome />
          </ContentHome>
          <SocialFeedContainer>
            <MainSocialFeed />
          </SocialFeedContainer>
        </>
      </PageTemplate>
    </>
  );
};
