import { useState, useEffect } from 'react';
import { useClient } from '../../../autogenerated/client/client';
import { TemplatesEmailItem } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import { fragmentBulletinMaterials } from '../FormInformativos/FormInformativos';

export const useInformativeViewer = (id: string) => {
  const [html, setHTML] = useState('');
  const [titleMaterial, setTitleMaterial] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateProps, setTemplateProps] = useState<TemplatesEmailItem>();
  const [downloadUrlAttachment, setDownloadUrlAttachment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const file = useClient('FileFindByIds');
  const material = useClient('AnalyticsMaterialView', {
    appendToFragment: `
      bulletinMaterials {
        ${fragmentBulletinMaterials}
      }

      sentEmail {
        body
      }

      communicationItemsConfig {
        kind
        templateName
        templateProps {
          TITLE_1
          TITLE_2
          TITLE_3
          TEXT_1
          TEXT_2
          TEXT_3
          TEXT_4
          IMAGE_SRC_1
          BUTTON_TITLE
          BUTTON_LINK
          HIDE_BUTTON
        }
        attachmentIds
      }
    `,
  });

  function updateTemplateProps(email: any, signedUrl: string) {
    setTemplateProps({
      defaultProps: {
        ...email?.templateProps,
        IMAGE_SRC_1: signedUrl
      },
      hideButton: !!email?.templateProps?.HIDE_BUTTON
    });
    setIsLoading(false);
  }

  function findFile(
    email: any,
    type: 'image' | 'file',
    _ids?: string[],
    matchID?: string
  ) {
    if (!_ids || !_ids?.length) return;
    file
      .fetch({
        variables: {
          _ids,
          limit: 2
        }
      })
      .then((ctx) => {
        const result = ctx.result;

        if (result && type === 'image') {
          const image = result.find((item) => item?._id === matchID);
          updateTemplateProps(email, image?.signedUrl || '');
        }

        if (result && type === 'file') {
          const downloadUrl = result.find((item) => item?._id)?.signedUrl;
          !!downloadUrl && setDownloadUrlAttachment(downloadUrl);
        }

        setIsLoading(false);
      });
  }

  useEffect(() => {
    if(id && id !== "") {
      material.fetch({ variables: { _id: id } }).then((ctx) => {
        const emailConfig = ctx.result?.communicationItemsConfig?.find((i) => i)!;
        const emailAttachments: any = emailConfig?.attachmentIds || undefined;
  
        setTitleMaterial(ctx.result?.title || '');
        setHTML(ctx.result?.sentEmail?.body || '');
  
        findFile(emailConfig, 'file', emailAttachments);
  
        if(ctx?.result?.bulletinMaterials?.length) {
          setFiles(ctx.result.bulletinMaterials);
        }
  
        if (emailConfig?.templateName) {
          setTemplateName(emailConfig?.templateName || '');
  
          const { IMAGE_SRC_1 } = emailConfig?.templateProps!;
          let _ids: any[] = [];
  
          if (IMAGE_SRC_1 && IMAGE_SRC_1 !== 'none') {
            _ids = [IMAGE_SRC_1].filter(
              (item) => item.indexOf('data') < 0 && item.indexOf('/static') < 0
            );
          }
  
          if (_ids.length > 0) {
            if (_ids[0].indexOf('https://') === 0) {
              updateTemplateProps(emailConfig, _ids[0]);
            } else {
              findFile(emailConfig, 'image', _ids, IMAGE_SRC_1!);
            }
          } else {
            updateTemplateProps(emailConfig, '');
          }
        } else {
          setIsLoading(false);
          setIsError(true);
        }
      });
    }
  }, [id]);

  return {
    titleMaterial,
    templateName,
    templateProps,
    downloadUrlAttachment,
    isLoading,
    isError,
    html,
    files
  };
};
