import React, { useState } from 'react';
import { Button } from '@digi-tim-19/components';

import PraConectarLogo from '../../assets/images/logo-corporativo-blue.png';
import { BlueTimHeader } from '../../components/Page/BlueTimHeader/BlueTimHeader';

import { FormerLogin } from './FormerLogin';
import { FormerValidation } from './FormerValidation';
import * as S from './styles';
import styled from 'styled-components';

type TStepProps = 'choice' | 'validation' | 'login';

const ButtonStyled = styled(Button)`
  @media screen and (max-width: 540px) {
    white-space: normal !important;
  }
`;

export const LoginCPF = () => {
  const [step, setStep] = useState<TStepProps>('choice');
  const [userCPF, setUserCPF] = useState<string | undefined>(undefined);

  return (
    <S.Wrapper>
      <BlueTimHeader />
      <S.Container>
        <S.LoginWrapper>
          <S.Logo src={PraConectarLogo} alt="Corporativo logo" />

          {step === 'choice' && (
            <S.ButtonWrapper>
              <ButtonStyled
                onClick={() => (document.location.href = '/portal')}
              >
                Login
              </ButtonStyled>
              <ButtonStyled onClick={() => setStep('validation')}>
                Se é inativo, utilize seu CPF, e acesse por aqui
              </ButtonStyled>
            </S.ButtonWrapper>
          )}

          {step === 'validation' && (
            <FormerValidation
              onCancel={() => setStep('choice')}
              onSuccess={(CPF) => {
                setUserCPF(CPF);
                setStep('login');
              }}
            />
          )}

          {step === 'login' && (
            <FormerLogin
              defaultCPF={userCPF}
              onCancel={() => setStep('validation')}
            />
          )}
        </S.LoginWrapper>
      </S.Container>
    </S.Wrapper>
  );
};
