"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var image_extensions_1 = require("../image-extensions");

exports.routesCorp = {
  example: {
    path: '/example/:example_id',
    mount: function mount(example_id) {
      return "/custom-example/".concat(example_id);
    }
  },
  home: {
    path: '/',
    mount: function mount() {
      return '/';
    }
  },
  homePortal: {
    path: '/portal',
    mount: function mount() {
      return '/portal';
    }
  },
  unsubscribe: {
    path: '/open/unsubscribe',
    mount: function mount() {
      return '/open/unsubscribe';
    }
  },
  login: {
    path: '/login',
    mount: function mount() {
      return '/open/login';
    }
  },
  loginCPF: {
    path: '/open/login',
    mount: function mount() {
      return '/open/login';
    }
  },
  changePassword: {
    path: '/open/alterar-senha',
    mount: function mount() {
      return "/open/alterar-senha";
    }
  },
  meuDiaNoPdv: {
    path: '/open/meudianopdv',
    mount: function mount() {
      return "/open/meudianopdv";
    }
  },
  favoritos: {
    path: '/favoritos',
    mount: function mount() {
      return '/favoritos';
    }
  },
  notificacoes: {
    path: '/notificacoes',
    mount: function mount() {
      return '/notificacoes';
    }
  },
  notificacoesCadastrar: {
    path: '/gerenciar/notificacoes/cadastrar',
    mount: function mount() {
      return '/gerenciar/notificacoes/cadastrar';
    }
  },
  notificacoesGerenciar: {
    path: '/gerenciar/notificacoes/listar',
    mount: function mount() {
      return '/gerenciar/notificacoes/listar';
    }
  },
  notificacoesEditar: {
    path: '/gerenciar/notificacoes/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/notificacoes/editar/".concat(id);
    }
  },
  documentos: {
    path: '/documento/:menu/:category/:product',
    mount: function mount(menu, category, product) {
      return "/documento/".concat(menu, "/").concat(category, "/").concat(product);
    }
  },
  documentosSubCategoria: {
    path: '/documento/:menu/:category/:product/:subCategory',
    mount: function mount(menu, category, product, subCategory) {
      return "/documento/".concat(menu, "/").concat(category, "/").concat(product, "/").concat(subCategory);
    }
  },
  documentosSubSubCategoria: {
    path: '/documento/:menu/:category/:product/:subCategory/:subSubCategory',
    mount: function mount(menu, category, product, subCategory, subSubCategory) {
      return "/documento/".concat(menu, "/").concat(category, "/").concat(product, "/").concat(subCategory, "/").concat(subSubCategory);
    }
  },
  documentosCategoria: {
    path: '/documento/:menu/:category/',
    mount: function mount(menu, category) {
      return "/documento/".concat(menu, "/").concat(category);
    }
  },
  documentosMenu: {
    path: '/documento/:menu',
    mount: function mount(menu) {
      return "/documento/".concat(menu);
    }
  },
  documentosGerenciar: {
    path: '/gerenciar/documentos/listar',
    mount: function mount() {
      return '/gerenciar/documentos/listar';
    }
  },
  documentosCadastrar: {
    path: '/gerenciar/documentos/cadastrar',
    mount: function mount() {
      return '/gerenciar/documentos/cadastrar';
    }
  },
  documentosEditar: {
    path: '/gerenciar/documentos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/documentos/editar/".concat(id);
    }
  },
  atividadesRecentes: {
    path: '/atividades-recentes',
    mount: function mount() {
      return '/atividades-recentes';
    }
  },
  linksUteis: {
    path: '/links-uteis',
    mount: function mount() {
      return '/links-uteis';
    }
  },
  videos: {
    path: '/videos',
    mount: function mount() {
      return '/videos';
    }
  },
  search: {
    path: '/busca/:search',
    mount: function mount(search) {
      return "/busca/".concat(search);
    }
  },
  noticias: {
    path: '/noticias/:menu/:category/',
    mount: function mount(menu, category) {
      return "/noticias/".concat(menu, "/").concat(category);
    }
  },
  noticiasConteudo: {
    path: '/noticias/:id',
    mount: function mount(id) {
      return "/noticias/".concat(id);
    }
  },
  linksUteisListar: {
    path: '/gerenciar/links-uteis/listar',
    mount: function mount() {
      return '/gerenciar/links-uteis/listar';
    }
  },
  linksUteisEditar: {
    path: '/gerenciar/links-uteis/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/links-uteis/editar/".concat(id);
    }
  },
  linksUteisCadastrar: {
    path: '/gerenciar/links-uteis/cadastrar',
    mount: function mount() {
      return '/gerenciar/links-uteis/cadastrar';
    }
  },
  noticiasCadastrar: {
    path: '/gerenciar/noticias/cadastrar',
    mount: function mount() {
      return '/gerenciar/noticias/cadastrar';
    }
  },
  noticiasListar: {
    path: '/gerenciar/noticias/listar',
    mount: function mount() {
      return '/gerenciar/noticias/listar';
    }
  },
  noticiasEditar: {
    path: '/gerenciar/noticias/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/noticias/editar/".concat(id);
    }
  },
  campanhasIncentivo: {
    path: '/campanha-incentivo/:id',
    mount: function mount(id) {
      return "/campanha-incentivo/".concat(id);
    }
  },
  campanhasIncentivoVigentes: {
    path: '/campanhas-incentivo/vigentes',
    mount: function mount() {
      return '/campanhas-incentivo/vigentes';
    }
  },
  campanhasIncentivoEncerradas: {
    path: '/campanhas-incentivo/encerradas',
    mount: function mount() {
      return '/campanhas-incentivo/encerradas';
    }
  },
  campanhasIncentivoResultados: {
    path: '/campanhas-incentivo/resultados',
    mount: function mount() {
      return '/campanhas-incentivo/resultados';
    }
  },
  campanhasIncentivoListar: {
    path: '/gerenciar/campanhas-incentivo/listar',
    mount: function mount() {
      return '/gerenciar/campanhas-incentivo/listar';
    }
  },
  campanhasIncentivoEditar: {
    path: '/gerenciar/campanhas-incentivo/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/campanhas-incentivo/editar/".concat(id);
    }
  },
  campanhasIncentivoCadastrar: {
    path: '/gerenciar/campanhas-incentivo/cadastrar',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/cadastrar";
    }
  },
  campanhasIncentivoUploadPontos: {
    path: '/gerenciar/campanhas-incentivo/upload-tim-coins',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/upload-tim-coins";
    }
  },
  campanhasIncentivoEstornoUploadExtraListar: {
    path: '/gerenciar/campanhas-incentivo/estorno-e-upload-extra',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/estorno-e-upload-extra";
    }
  },
  campanhasIncentivoEstornoUploadExtra: {
    path: '/gerenciar/campanhas-incentivo/estorno-e-upload-extra/:id',
    mount: function mount(id) {
      return "/gerenciar/campanhas-incentivo/estorno-e-upload-extra/".concat(id);
    }
  },
  videosPlayer: {
    path: '/videos/:id',
    mount: function mount(id) {
      return "/videos/".concat(id);
    }
  },
  videosPlaylist: {
    path: '/videos/playlist/:id',
    mount: function mount(id) {
      return "/videos/playlist/".concat(id);
    }
  },
  videosPlaylistCadastrar: {
    path: '/gerenciar/videos/playlist/cadastrar',
    mount: function mount() {
      return '/gerenciar/videos/playlist/cadastrar';
    }
  },
  videosPlaylistListar: {
    path: '/gerenciar/videos/playlist/listar',
    mount: function mount() {
      return '/gerenciar/videos/playlist/listar';
    }
  },
  videosPlaylistEditar: {
    path: '/gerenciar/videos/playlist/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/videos/playlist/editar/".concat(id);
    }
  },
  videosListar: {
    path: '/gerenciar/videos/listar',
    mount: function mount() {
      return '/gerenciar/videos/listar';
    }
  },
  videosCadastrar: {
    path: '/gerenciar/videos/cadastrar',
    mount: function mount() {
      return '/gerenciar/videos/cadastrar';
    }
  },
  videosEditar: {
    path: '/gerenciar/videos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/videos/editar/".concat(id);
    }
  },
  pdfViewer: {
    path: '/visualizar/:id',
    mount: function mount(id) {
      return "/visualizar/".concat(id);
    }
  },
  htmlViewer: {
    path: '/material/conteudo/:id',
    mount: function mount(id) {
      return "/material/conteudo/".concat(id);
    }
  },
  imageViewer: {
    path: '/visualizar/image/:id',
    mount: function mount(id) {
      return "/visualizar/image/".concat(id);
    }
  },
  filesViewer: {
    path: '/visualizar/documentos/:id',
    mount: function mount(id) {
      return "/visualizar/documentos/".concat(id);
    }
  },
  pdfEventViewer: {
    path: '/visualizar/evento/:id',
    mount: function mount(id) {
      return "/visualizar/evento/".concat(id);
    }
  },
  htmlEventViewer: {
    path: '/evento/conteudo/:id',
    mount: function mount(id) {
      return "/evento/conteudo/".concat(id);
    }
  },
  imageEventViewer: {
    path: '/visualizar/evento/image/:id',
    mount: function mount(id) {
      return "/visualizar/evento/image/".concat(id);
    }
  },
  filesEventViewer: {
    path: '/visualizar/documentos/evento/:id',
    mount: function mount(id) {
      return "/visualizar/documentos/evento/".concat(id);
    }
  },
  informativos: {
    path: '/informativos/:menu/:category/',
    mount: function mount(menu, category) {
      return "/informativos/".concat(menu, "/").concat(category);
    }
  },
  informativosResidencial: {
    path: '/informativos',
    mount: function mount() {
      return "/informativos";
    }
  },
  informativosListar: {
    path: '/gerenciar/informativos/listar',
    mount: function mount() {
      return '/gerenciar/informativos/listar';
    }
  },
  informativosEditar: {
    path: '/gerenciar/informativos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/informativos/editar/".concat(id);
    }
  },
  informativosCadastrar: {
    path: '/gerenciar/informativos/cadastrar',
    mount: function mount() {
      return '/gerenciar/informativos/cadastrar';
    }
  },
  relatoriosDashboard: {
    path: '/relatorios/dashboard',
    mount: function mount() {
      return '/relatorios/dashboard';
    }
  },
  relatoriosUsuarios: {
    path: '/relatorios/usuarios',
    mount: function mount() {
      return '/relatorios/usuarios';
    }
  },
  relatoriosPontosExpirados: {
    path: '/relatorios/tim-coins',
    mount: function mount() {
      return '/relatorios/tim-coins';
    }
  },
  contestacaoListar: {
    path: '/gerenciar/contestacao/listar',
    mount: function mount() {
      return '/gerenciar/contestacao/listar';
    }
  },
  contestacaoEditar: {
    path: '/gerenciar/contestacao/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/contestacao/editar/".concat(id);
    }
  },
  minhasContestacoesListar: {
    path: '/contestacao/listar',
    mount: function mount() {
      return '/contestacao/listar';
    }
  },
  minhaContestacaoVisualizar: {
    path: '/contestacao/visualizar/:id',
    mount: function mount(id) {
      return "/contestacao/visualizar/".concat(id);
    }
  },
  bannersListar: {
    path: '/gerenciar/banners/listar',
    mount: function mount() {
      return '/gerenciar/banners/listar';
    }
  },
  bannersCadastrar: {
    path: '/gerenciar/banners/cadastrar',
    mount: function mount() {
      return '/gerenciar/banners/cadastrar';
    }
  },
  bannersEditar: {
    path: '/gerenciar/banners/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/banners/editar/".concat(id);
    }
  },
  ordemPagamentoCadastrar: {
    path: '/digi/ordem-pagamento/cadastrar',
    mount: function mount() {
      return "/digi/ordem-pagamento/cadastrar";
    }
  },
  ordemPagamentoListar: {
    // esta rota pode ser vista tanto pela equipe digi quanto tim
    path: '/gerenciar/ordem-pagamento/listar',
    mount: function mount() {
      return '/gerenciar/ordem-pagamento/listar';
    }
  },
  ordemPagamentoEditar: {
    path: '/digi/ordem-pagamento/editar/:id',
    mount: function mount(id) {
      return "/digi/ordem-pagamento/editar/".concat(id);
    }
  },
  usuariosAprovadores: {
    path: '/gerenciar/usuarios/aprovadores',
    mount: function mount() {
      return '/gerenciar/usuarios/aprovadores';
    }
  },
  usuariosListar: {
    path: '/gerenciar/usuarios/listar',
    mount: function mount() {
      return '/gerenciar/usuarios/listar';
    }
  },
  informacoesExtrato: {
    path: '/informacoes-extrato',
    mount: function mount() {
      return "/informacoes-extrato";
    }
  },
  visualizarInformativo: {
    path: '/visualizar-informativo/:id',
    mount: function mount(id) {
      return "/visualizar-informativo/".concat(id);
    }
  },
  aprovacoes: {
    path: '/aprovacoes',
    mount: function mount() {
      return "/aprovacoes";
    }
  },
  pesquisa: {
    path: '/pesquisa',
    mount: function mount() {
      return '/pesquisa';
    }
  },
  pesquisaListar: {
    path: '/gerenciar/pesquisa/listar',
    mount: function mount() {
      return '/gerenciar/pesquisa/listar';
    }
  },
  pesquisaCadastrar: {
    path: '/gerenciar/pesquisa/cadastrar',
    mount: function mount() {
      return '/gerenciar/pesquisa/cadastrar';
    }
  },
  downloadIncentivo: {
    path: '/download-incentivo',
    mount: function mount() {
      return "/download-incentivo";
    }
  },
  chat: {
    path: '/chat',
    mount: function mount() {
      return "/chat";
    }
  },
  eventos: {
    path: '/eventos',
    mount: function mount() {
      return '/eventos';
    }
  },
  eventosListar: {
    path: '/evento/:menu/:category/:product',
    mount: function mount(menu, category, product) {
      return "/evento/".concat(menu, "/").concat(category, "/").concat(product);
    }
  },
  eventosGerenciar: {
    path: '/gerenciar/eventos/listar',
    mount: function mount() {
      return '/gerenciar/eventos/listar';
    }
  },
  eventosCadastrar: {
    path: '/gerenciar/eventos/cadastrar',
    mount: function mount() {
      return '/gerenciar/eventos/cadastrar';
    }
  },
  eventosEditar: {
    path: '/gerenciar/eventos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/eventos/editar/".concat(id);
    }
  },
  eventViewer: {
    path: '/evento/:id',
    mount: function mount(id) {
      return "/evento/".concat(id);
    }
  },
  eventosComponentes: {
    path: '/gerenciar/eventos/componentes',
    mount: function mount() {
      return '/gerenciar/eventos/componentes';
    }
  },
  eventosComponentesEditar: {
    path: '/gerenciar/eventos/componentes/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/eventos/componentes/editar/".concat(id);
    }
  },
  eventosModeracao: {
    path: '/gerenciar/eventos/moderacao',
    mount: function mount() {
      return "/gerenciar/eventos/moderacao";
    }
  },
  eventosModeracaoEditar: {
    path: '/gerenciar/eventos/moderacao/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/eventos/moderacao/editar/".concat(id);
    }
  },
  researchCadastrar: {
    path: '/gerenciar/pesquisas/cadastrar',
    mount: function mount() {
      return '/gerenciar/pesquisas/cadastrar';
    }
  },
  researchEditar: {
    path: '/gerenciar/pesquisas/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/pesquisas/editar/".concat(id);
    }
  },
  researchGerenciar: {
    path: '/gerenciar/pesquisas/listar',
    mount: function mount() {
      return '/gerenciar/pesquisas/listar';
    }
  },
  researchRespostas: {
    path: '/gerenciar/pesquisas/respostas/listar',
    mount: function mount() {
      return '/gerenciar/pesquisas/respostas/listar';
    }
  },
  researchRespostasUsuarios: {
    path: '/gerenciar/pesquisas/respostas/usuarios/:id',
    mount: function mount(id) {
      return "/gerenciar/pesquisas/respostas/usuarios/".concat(id);
    }
  },
  researchListar: {
    path: '/pesquisas/listar',
    mount: function mount() {
      return '/pesquisas/listar';
    }
  },
  researchResponder: {
    path: '/pesquisas/responder/:id',
    mount: function mount(id) {
      return "/pesquisas/responder/".concat(id);
    }
  },
  remuneracaoVariavelMetasCadastrar: {
    path: '/gerenciar/remuneracao-variavel-metas/cadastrar',
    mount: function mount() {
      return '/gerenciar/remuneracao-variavel-metas/cadastrar';
    }
  },
  remuneracaoVariavelMetasListar: {
    path: '/gerenciar/remuneracao-variavel-metas/listar',
    mount: function mount() {
      return '/gerenciar/remuneracao-variavel-metas/listar';
    }
  },
  remuneracaoVariavelMetasEditar: {
    path: '/gerenciar/remuneracao-variavel-metas/:id',
    mount: function mount(id) {
      return "/gerenciar/remuneracao-variavel-metas/".concat(id);
    }
  },
  remuneracaoVariavelMetasParticipanteListar: {
    path: '/remuneracao-variavel-metas/participante/listar',
    mount: function mount() {
      return '/remuneracao-variavel-metas/participante/listar';
    }
  },
  remuneracaoVariavelMetasParticipanteVisualizar: {
    path: '/remuneracao-variavel-metas/participante/visualizar/:id',
    mount: function mount(id) {
      return "/remuneracao-variavel-metas/participante/visualizar/".concat(id);
    }
  }
};

exports.getRouterByMaterialCorp = function (material, routes) {
  var _a;

  var routeFromMaterial = getRouteByKindMaterial(material, routes);
  var fileDownload = (_a = material.files) === null || _a === void 0 ? void 0 : _a.find(function (item) {
    var _a, _b, _c;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) !== 'pdf' && ((_b = item) === null || _b === void 0 ? void 0 : _b.kind) !== 'mailing' && !image_extensions_1.isImageExtension((_c = item) === null || _c === void 0 ? void 0 : _c.extension);
  });
  return {
    routerAccess: routeFromMaterial ? routeFromMaterial : undefined,
    routerDownload: getDownloadUrl(fileDownload),
    routerEdit: editUrl(material)
  };
};

exports.getRouterByEventCorp = function (event, routes) {
  var _a;

  var routeFromEvent = getRouteByKindEvent(event, routes);
  var fileDownload = (_a = event.files) === null || _a === void 0 ? void 0 : _a.find(function (item) {
    var _a, _b, _c;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) !== 'pdf' && ((_b = item) === null || _b === void 0 ? void 0 : _b.kind) !== 'mailing' && !image_extensions_1.isImageExtension((_c = item) === null || _c === void 0 ? void 0 : _c.extension);
  });
  return {
    routerAccess: routeFromEvent ? routeFromEvent : undefined,
    routerDownload: getDownloadUrl(fileDownload),
    routerEdit: editUrl(event)
  };
};

var getDownloadUrl = function getDownloadUrl(fileDownload) {
  var _a, _b;

  if ((_a = fileDownload) === null || _a === void 0 ? void 0 : _a.signedUrl) return (_b = fileDownload) === null || _b === void 0 ? void 0 : _b.signedUrl;
  return undefined;
};

var editUrl = function editUrl(material) {
  var kind = material.kind,
      _id = material._id;

  switch (kind) {
    case 'communication':
      {
        return exports.routesCorp.informativosEditar.mount(_id);
      }

    case 'document':
      {
        return exports.routesCorp.documentosEditar.mount(_id);
      }

    case 'news':
      {
        return exports.routesCorp.noticiasEditar.mount(_id);
      }

    case 'links_uteis':
      {
        return exports.routesCorp.linksUteisEditar.mount(_id);
      }

    case 'video':
      {
        return exports.routesCorp.videosEditar.mount(_id);
      }

    default:
      {
        // adicionando banner como default pois ele não é um material
        return exports.routesCorp.bannersEditar.mount(_id);
      }
  }
};

function getRouteByKindMaterial(material, routes) {
  var _a, _b;

  switch (material.kind) {
    case 'video':
      return routes.videosPlayer.mount(material._id);

    case 'news':
      return routes.noticiasConteudo.mount(material._id);

    case 'communication':
      var isEmail = (_b = (_a = material) === null || _a === void 0 ? void 0 : _a.communicationItemsConfig) === null || _b === void 0 ? void 0 : _b.find(function (el) {
        return ['email', 'bulletin'].includes(el.kind);
      });
      if (isEmail) return routes.visualizarInformativo.mount(material._id);
      return getRouterByFileMaterial(material, routes);

    default:
      return getRouterByFileMaterial(material, routes);
  }
}

function getRouteByKindEvent(event, routes) {
  var _a, _b;

  switch (event.kind) {
    case 'video':
      return routes.videosPlayer.mount(event._id);

    case 'news':
      return routes.noticiasConteudo.mount(event._id);

    case 'communication':
      var isEmail = (_b = (_a = event) === null || _a === void 0 ? void 0 : _a.communicationItemsConfig) === null || _b === void 0 ? void 0 : _b.find(function (el) {
        return ['email', 'bulletin'].includes(el.kind);
      });
      if (isEmail) return routes.visualizarInformativo.mount(event._id);
      return getRouterByFileEvent(event, routes);

    default:
      return getRouterByFileEvent(event, routes);
  }
}

function getRouterByFileMaterial(material, routes) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;

  var docFiles = (_a = material.files) === null || _a === void 0 ? void 0 : _a.map(function (item) {
    var _a;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.kind) !== 'card_thumb';
  }).filter(function (el) {
    return el;
  });
  var filePDF = (_b = material.files) === null || _b === void 0 ? void 0 : _b.find(function (item) {
    var _a;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) === 'pdf';
  });
  var fileDocx = (_c = material.files) === null || _c === void 0 ? void 0 : _c.find(function (item) {
    var _a;

    return ['doc', 'docx'].includes((_a = item) === null || _a === void 0 ? void 0 : _a.extension);
  });
  var fileImg = (_d = material.files) === null || _d === void 0 ? void 0 : _d.find(function (item) {
    var _a, _b, _c;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.kind) !== 'card_thumb' && ((_b = item) === null || _b === void 0 ? void 0 : _b.kind) !== 'notification_image' && image_extensions_1.isImageExtension((_c = item) === null || _c === void 0 ? void 0 : _c.extension);
  });

  if (((_f = (_e = material) === null || _e === void 0 ? void 0 : _e.sentEmail) === null || _f === void 0 ? void 0 : _f.body) || ((_g = material) === null || _g === void 0 ? void 0 : _g.html) || ((_k = (_j = (_h = material) === null || _h === void 0 ? void 0 : _h.email) === null || _j === void 0 ? void 0 : _j.html) === null || _k === void 0 ? void 0 : _k.body) || ((_m = (_l = material) === null || _l === void 0 ? void 0 : _l.editor) === null || _m === void 0 ? void 0 : _m.length)) {
    return routes.htmlViewer.mount(material._id);
  }

  if (((_o = docFiles) === null || _o === void 0 ? void 0 : _o.length) > 1) return routes.filesViewer.mount(material._id);
  if (filePDF || fileDocx) return routes.pdfViewer.mount(material._id);
  if (fileImg) return routes.imageViewer.mount(material._id);
  return undefined;
}

function getRouterByFileEvent(event, routes) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;

  var docFiles = (_a = event.files) === null || _a === void 0 ? void 0 : _a.map(function (item) {
    var _a;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.kind) !== 'card_thumb';
  }).filter(function (el) {
    return el;
  });
  var filePDF = (_b = event.files) === null || _b === void 0 ? void 0 : _b.find(function (item) {
    var _a;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) === 'pdf';
  });
  var fileDocx = (_c = event.files) === null || _c === void 0 ? void 0 : _c.find(function (item) {
    var _a;

    return ['doc', 'docx'].includes((_a = item) === null || _a === void 0 ? void 0 : _a.extension);
  });
  var fileImg = (_d = event.files) === null || _d === void 0 ? void 0 : _d.find(function (item) {
    var _a, _b, _c;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.kind) !== 'card_thumb' && ((_b = item) === null || _b === void 0 ? void 0 : _b.kind) !== 'notification_image' && image_extensions_1.isImageExtension((_c = item) === null || _c === void 0 ? void 0 : _c.extension);
  });

  if (((_f = (_e = event) === null || _e === void 0 ? void 0 : _e.sentEmail) === null || _f === void 0 ? void 0 : _f.body) || ((_g = event) === null || _g === void 0 ? void 0 : _g.html) || ((_k = (_j = (_h = event) === null || _h === void 0 ? void 0 : _h.email) === null || _j === void 0 ? void 0 : _j.html) === null || _k === void 0 ? void 0 : _k.body) || ((_m = (_l = event) === null || _l === void 0 ? void 0 : _l.editor) === null || _m === void 0 ? void 0 : _m.length)) {
    return routes.htmlEventViewer.mount(event._id);
  }

  if (((_o = docFiles) === null || _o === void 0 ? void 0 : _o.length) > 1) return routes.filesEventViewer.mount(event._id);
  if (filePDF || fileDocx) return routes.pdfEventViewer.mount(event._id);
  if (fileImg) return routes.imageEventViewer.mount(event._id);
  return undefined;
}