import React,
{
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import {
  SocialFeed,
  CardSocialFeedProps,
  TypeContentSocialFeed,
  MaterialProvider,
  ShareModal,
  ProviderSelecionarPublico,
  useCurrentUser
} from '@digi-tim-19/components';
import { useClient } from '../../autogenerated/client/client';
import {
  EnumMaterialKind,
  EnumFileKind,
  EnumMaterialStatus,
  SortFindManyMaterialInput,
  MutationMaterialUpdateOneArgs
} from '../../autogenerated/client/types';
import { formatDate } from '@digi-tim-19/utils/build';
import { message } from 'antd';

export const MainSocialFeed = () => {
  const user = useCurrentUser().result;
  const getMaterialPagination = useClient('MaterialPagination');
  const getMaterialPinned = useClient('MaterialFindPinned');
  const createMaterial = useClient('MaterialCreateOne');
  const MaterialUpdateOne = useClient('MaterialUpdateOne');
  const MaterialDeleteOne = useClient('MaterialRemoveOne');
  const MaterialLikeToggle = useClient('MaterialLikeToggle');
  const FileFindById = useClient('FileFindById', {
    appendToFragment: `signedUrl`
  });
  const [filterPinned, setFilterPinned] = useState(false);
  const [filterData, setFilterData] = useState<any>([]);
  const [items, setItems] = useState<CardSocialFeedProps[]>([]);
  const [publish, setPublish] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);
  const [resultMaterial, setResultMaterial] = useState<any>([]);
  const [isTrade, setIsTrade] = useState(false);
  const [newFind, setNewFind] = useState(false);
  const [urlImgIcon, setUrlImgIcon] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(1);

  const appendToFragment = `
  pageInfo {
    perPage
    hasNextPage
    hasPreviousPage
    itemCount
    pageCount
  }
  items {
    _id
    description
    publishedAt
    pinned
    pinnedForAll
    link
    likes {
      liked
    }
    author {
      name
      imageId
      signedUrl
    }
    files {
      _id
      title
      kind
      signedUrl
      downloadUrl
    }
    availableAtRegions
    availableAtChannels
    availableAtRoleGroups
    availableAtRegionalProfiles
  }
  `;

  const configFetch = {
    appendToFragment,
    variables: {
      filter: {
        kind: EnumMaterialKind.SocialFeed
      },
      sort: SortFindManyMaterialInput.CreatedatDesc,
      perPage: 3,
      page: 1
    }
  };

  useEffect(() => {
    if (!filterPinned) {
      getMaterialPagination.fetch({ ...configFetch, variables: { ...configFetch.variables, page } }).then(setThen);
    } else {
      getMaterialPinned.fetch({ ...configFetch, variables: { ...configFetch.variables, page } }).then(setThen);
    }

    function setThen(ctx: any) {
      if (ctx.result && ctx.result.items) {
        setResultMaterial((prev: any) => {
          if(page === 1) return ctx.result.items;
          return [ ...prev, ...ctx.result.items ];
        });
      }
      if(page === 1) {
        setIsLoading(false);
      }
      setIsLoadingShowMore(false);
    }
  }, [publish, filterPinned, newFind, page]);

  const onSearchCallback = useCallback(
    (e) => {
      setFilterData(
        items?.filter((material) =>
          material.description.toLowerCase().includes(e.toLowerCase())
        )
      );
    },
    [items]
  );

  const getAvatarImage = (_id: string) => {
    FileFindById.fetch({ variables: { _id } }).then(({ result }) => {
      if(result?.signedUrl) {
        setUrlImgIcon(result.signedUrl);
      }
    });
  }

  useEffect(() => {
    const itemsResult = resultMaterial.map((item: any) =>
      mapResultMaterial(item)
    );

    setItems(itemsResult);
  }, [resultMaterial, isTrade]);

  useMemo(() => {
    const adms = [
      'residencial:adm_trade_residencial',
      'corporate:adm_trade_corporate'
    ];

    const isTrade = user?.roleGroup?._id && adms.includes(user?.roleGroup._id);

    if(user?.avatarId) {
      getAvatarImage(user.avatarId);
    }

    setIsTrade(!!isTrade);
  }, [user]);

  const configSocialFeed = useMemo(() => {
    return {
      urlImgIcon: urlImgIcon || '/defaultImages/icon-socialFeed.png',
      isLoading: isLoading || isLoadingShowMore,
      items: filterData.length > 0 ? filterData : items,
      showMore: getMaterialPagination?.result?.count && items.length < getMaterialPagination?.result?.count || false,
      onSearch: onSearchCallback,
      onClickPinFilter: () => {
        if (filterPinned) {
          setFilterPinned(false);
          setFilterData([]);
        } else {
          const filtered = resultMaterial
            .map(mapResultMaterial)
            .filter((item: CardSocialFeedProps) => item.pinnedForAll === true);
          setFilterPinned(true);
          setFilterData(filtered);
        }
      },
      onChangePage: () => {
        setIsLoadingShowMore(true);
        setPage(prev => prev + 1);
      },
      onClickPublish: async (
        idFile: string,
        legend: string,
        publicSelected: any,
        _idEdit?: string,
        link?: string,
        profile?: {
          imageId?: string;
          name?: string;
        }
      ) => {
        const title = legend.length > 15 ? legend.substring(0, 15) : legend;

        const record = {
          ...publicSelected,
          kind: EnumMaterialKind.SocialFeed,
          status: EnumMaterialStatus.Approved,
          validity: {
            start: new Date().toISOString()
          },
          fileIds: [idFile].filter(Boolean),
          link: link,
          title: `Post ${title}`,
          description: legend,
        };

        if(profile?.imageId && profile?.imageId !== user?.avatarId) {
          record.profile = { imageId: profile.imageId };
        }

        if(profile?.name && profile?.name !== user?.name) {
          record.profile = { ...record?.profile, name: profile.name };
        }

        setPage(1);

        if (_idEdit) {
          await MaterialUpdateOne.fetch({
            variables: { record, filter: { _id: _idEdit } },
            afterMutate: /^Material/
          }).then((ctx) => {
            if (!ctx.errors) {
              setNewFind(!newFind);
            } else {
              message.warn('Erro ao atualizar post');
            }
          });
        } else {
          await createMaterial
            .fetch({ variables: { record }, afterMutate: /^Material/ })
            .then((ctx) => {
              if (ctx?.result) {
                setNewFind(!newFind);
              } else {
                message.warn('Erro ao publicar post');
              }
            });
        }
      }
    };
  }, [items, filterData, isLoading, isLoadingShowMore]);

  return (
    <ProviderSelecionarPublico>
      {
        !isLoading
        &&
        <SocialFeed
          {...configSocialFeed}
        />
      }
    </ProviderSelecionarPublico>
  );

  function mapResultMaterial(item?: any): CardSocialFeedProps {
    const fileSocial = item?.files?.find(
      (file: any) =>
        file?.kind === EnumFileKind.SocialFeedImage ||
        file?.kind === EnumFileKind.SocialFeedVideo
    );

    const initialValues = {
      _id: item?._id || '',
      idFile: fileSocial?._id || '',
      file: fileSocial,
      legend: item?.description || '',
      link: item?.link || '',
      selectedPublic: {
        availableAtRegions: item?.availableAtRegions,
        availableAtChannels: item?.availableAtChannels,
        availableAtRoleGroups: item?.availableAtRoleGroups,
        availableAtRegionalProfiles: item?.availableAtRegionalProfiles
      },
      profile: {
        imageId: item?.author?.imageId,
        name: item?.author?.name || 'Pra Conectar',
        url: item?.author?.signedUrl || '/defaultImages/icon-socialFeed.png',
      }
    };

    const handlePinnedForAll = async (_id: any, isPinned: boolean) => {
      const variables: MutationMaterialUpdateOneArgs = {
        record: { pinnedForAll: isPinned },
        filter: { _id }
      };
      const errorMessage = isPinned
        ? 'Erro ao favoritar post'
        : 'Erro ao desfavoritar post';

      try {
        const ctx = await MaterialUpdateOne.fetch({ variables });
        if (ctx.errors) {
          message.warn(errorMessage);
        } else {
          setPublish(!publish);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return {
      _id: item?._id,
      platform: 'corp',
      nameUser: item?.author?.name || 'Pra Conectar',
      urlImageUser: item?.author?.signedUrl || '/defaultImages/icon-socialFeed.png',
      description: item?.description || '',
      publishedAt: formatDate(item?.publishedAt, 'DD/MM/YYYY'),
      urlContent: fileSocial?.signedUrl || '',
      typeContent:
        fileSocial?.kind === EnumFileKind.SocialFeedImage
          ? TypeContentSocialFeed.IMAGE
          : TypeContentSocialFeed.VIDEO,
      pinned: item.pinned,
      pinnedForAll: item.pinnedForAll,
      liked: item.likes.liked,
      canEdit: isTrade,
      initialValues,
      link: item?.link,
      onClickDelete: (_id: any) => {
        MaterialDeleteOne.fetch({
          variables: { filter: { _id: _id } },
          afterMutate: /^Material/
        }).then((ctx) => {
          if (ctx.result) {
            setNewFind(!newFind);
            message.success('Post excluido com sucesso');
          } else {
            message.warn('Ocorreu um erro ao remover o post');
          }
        });
      },
      onClickLike: (_id: string) => {
        MaterialLikeToggle.fetch({
          variables: { recordId: _id }
        }).then((ctx) => {
          if (ctx.errors) message.warn('Erro ao curtir post');
        });
      },
      renderShareModal: () => {
        return (
          <MaterialProvider material={item as any}>
            <ShareModal
              style={{
                height: 'auto',
                width: 'auto'
              }}
            />
          </MaterialProvider>
        );
      },
      onClickPin: isTrade || item.pinnedForAll ? handlePinnedForAll : undefined
    };
  }
};
